export const cards = [
  {
    id: 1,
    key: 1,
    img: "images/factions/chapter/cards/bash.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Chapter Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "BASH",
    lowerActionText:
      "Move, and then Push 1 enemy model (base_to_base) with this Knight to any Space adjacent to his original position.",
    squad: "HOLY_KNIGHT",
    count: 0,
    faction: "chapter",
  },
  {
    id: 2,
    key: 2,
    img: "images/factions/chapter/cards/charged_shot.png",
    cardCount: 2,
    upperAction: "DEFENSE",
    upperCost: "1",
    upperActionText:
      "1 model in this Squad gain +2 DEF for 1 roll. (1 use per roll)",
    lowerCost: "X",
    costType: "charge",
    lowerAction: "CHARGED SHOT",
    lowerActionText:
      "For each 2(charges) spent, add +1 Wound to each Hit of a single Purging action. (1 per Purging action)",
    squad: "PURGE_DOCTOR",
    count: 0,
    faction: "chapter",
  },
  {
    id: 3,
    key: 3,
    img: "images/factions/chapter/cards/energy_drain.png",
    cardCount: 4,
    upperAction: "CHARGING",
    upperCost: "1",
    upperActionText: "Charge all your Enhancements by 1(charge).",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "ENERGY DRAIN",
    lowerActionText:
      "Collect 1(crystal)*** from each Controlled Crystal Source with a Pilgrim in (base_to_base)",
    squad: "PILGRIM",
    count: 0,
    faction: "chapter",
  },
  {
    id: 4,
    key: 4,
    img: "images/factions/chapter/cards/fake_death.png",
    cardCount: 2,
    upperAction: "WORTHLESS",
    upperCost: "1",
    upperActionText:
      "Use right after a model of this Squad is killed. The opponent earns 2 VP less for killing it. (1 use per payment)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "FAKE DEATH",
    lowerActionText:
      "Ignore 1 Wound just received by any Pilgrim. Does not allow to Counterattack. (1 use per turn)",
    squad: "PILGRIM",
    count: 0,
    faction: "chapter",
  },
  {
    id: 5,
    key: 5,
    img: "images/factions/chapter/cards/firing_position.png",
    cardCount: 2,
    upperAction: "DEFENSE",
    upperCost: "1",
    upperActionText:
      "1 model in this Squad gain +2 DEF for 1 roll. (1 use per roll)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "FIRING POSITION",
    lowerActionText:
      "Each Ranger in this Squad shoots twice during each Shoot action.",
    squad: "RANGER",
    count: 0,
    faction: "chapter",
  },
  {
    id: 6,
    key: 6,
    img: "images/factions/chapter/cards/formation.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Chapter Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "FORMATION",
    lowerActionText:
      "Target 1 Holy Knight. All other Holy Knights in play are teleported (base_to_base) with him (you may Push enemies). Then, any of these Holy Knights may perform Combat.",
    squad: "HOLY_KNIGHT",
    count: 0,
    faction: "chapter",
  },
  {
    id: 7,
    key: 7,
    img: "images/factions/chapter/cards/guard.png",
    cardCount: 2,
    upperAction: "SUCCESSFUL  DEFENSE",
    upperCost: "2",
    upperActionText:
      "A Chapter model gets an automatic successful DEF roll. (1 use per roll)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "GUARD",
    lowerActionText:
      "One of your models (base_to_base) with this Holy Knight may use the Knight's DEF and Endurance tokens instead of its own. (1 use per payment)",
    squad: "HOLY_KNIGHT",
    count: 0,
    faction: "chapter",
  },
  {
    id: 8,
    key: 8,
    img: "images/factions/chapter/cards/guardian_angel.png",
    cardCount: 4,
    upperAction: "DEFENSE",
    upperCost: "1",
    upperActionText: "A Chapter model gain +2 to a DEF roll.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "GUARDIAN ANGEL",
    lowerActionText:
      "Use when one of your models within Range 3 of the Angel of Death is Wounded. The Angel suffers the WOunds instead.",
    squad: "ANGEL_OF_DEATH",
    count: 0,
    faction: "chapter",
  },
  {
    id: 9,
    key: 9,
    img: "images/factions/chapter/cards/judgement.png",
    cardCount: 2,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 user per turn)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "JUDGEMENT",
    lowerActionText:
      "Kill 1 enemy model (base_to_base) with the Angel with no Endurance tokens on its Squad card. (1 user per Payement)",
    squad: "ANGEL_OF_DEATH",
    count: 0,
    faction: "chapter",
  },
  {
    id: 10,
    key: 10,
    img: "images/factions/chapter/cards/last_man_standing.png",
    cardCount: 2,
    upperAction: "REVENGE",
    upperCost: "1",
    upperActionText:
      "Use right after a model of this Squad is killed in Combat. It may perform a Backstab before you remove it from teh board map. (1 use per combat)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "LAST MAN STANDING",
    lowerActionText: "If there's only 1 Pilgrim left, he gains +3 DEF",
    squad: "PILGRIM",
    count: 0,
    faction: "chapter",
  },
  {
    id: 11,
    key: 11,
    img: "images/factions/chapter/cards/leap.png",
    cardCount: 5,
    upperAction: "DODGE",
    upperCost: "2",
    upperActionText:
      "Use after a Chapter model enters Combat. Reposition this model to any adjacent Space and cancel the Combat.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "LEAP",
    lowerActionText:
      "Move (Range 4, through obstacles). Until end of turn this model automatically wins INI in any Combat.",
    squad: "HOLY_KNIGHT",
    count: 0,
    faction: "chapter",
  },
  {
    id: 12,
    key: 12,
    img: "images/factions/chapter/cards/overwatch.png",
    cardCount: 4,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "OVERWATCH",
    lowerActionText:
      "Perform 1 Backstab before 1 model of this Squad performs a Combat. (1 use per Combat)",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "chapter",
  },
  {
    id: 13,
    key: 13,
    img: "images/factions/chapter/cards/power_of_technology.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Chapter Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "POWER OF TECHNOLOGY",
    lowerActionText:
      "Destroy 1 Chapter (crystal) to put 3(charge) on any 1 of your Enhancements.",
    squad: "PURGE_DOCTOR",
    count: 0,
    faction: "chapter",
  },
  {
    id: 14,
    key: 14,
    img: "images/factions/chapter/cards/purging.png",
    cardCount: 4,
    upperAction: "CHARGING",
    upperCost: "1",
    upperActionText: "Charge all your Enhancements by 1 (charge).",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "PURGING",
    lowerActionText:
      "Basic mode required. Choose 2 adjacent Spaces, both adjacent to 1 Purge Dcotor. Check all models within these SPaces for Hit. Effect: 1 Wound. Put 1 FIRE token on 1 of the affected models.",
    squad: "PURGE_DOCTOR",
    count: 0,
    faction: "chapter",
  },
  {
    id: 15,
    key: 15,
    img: "images/factions/chapter/cards/shield_wall.png",
    cardCount: 4,
    upperAction: "DEFENSE",
    upperCost: "1",
    upperActionText: "A Chapter model gain +2 to a DEF roll.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "SHIELD WALL",
    lowerActionText:
      "Place a SHIELD WALL token on one group of (base_to_base) Holy Knights. Each of them gains +2 DEF for every other Holy Knight (base_to_base) with him. Holy Knights in this group cannot be Pushed. Remove the token when one of them is WOunded or moves.",
    squad: "HOLY_KNIGHT",
    count: 0,
    faction: "chapter",
  },
  {
    id: 16,
    key: 16,
    img: "images/factions/chapter/cards/shoot.png",
    cardCount: 8,
    upperAction: "DODGE",
    upperCost: "2",
    upperActionText:
      "Use after a Chapter model enters Combat. Reposition this model to any adjacent SPace and cancel the Combat.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "SHOOT",
    lowerActionText:
      "with all Rangers in a Squad at any 1 target model each (Range unlimited, Line of Fire required). Check targets for Hit. If in Aim mode, add 2 to the roll. Effect: 1 Wound. Shoot may be used instead of a Backstab or an Attack roll.",
    squad: "RANGER",
    count: 0,
    faction: "chapter",
  },
  {
    id: 17,
    key: 17,
    img: "images/factions/chapter/cards/storm_of_blades.png",
    cardCount: 5,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Chapter Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "STORM OF BLADES",
    lowerActionText: "Combat with each enemy (base_to_base) with the Angel.",
    squad: "ANGEL_OF_DEATH",
    count: 0,
    faction: "chapter",
  },
  {
    id: 18,
    key: 18,
    img: "images/factions/chapter/cards/tactic.png",
    cardCount: 4,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "TACTIC",
    lowerActionText:
      "Use after successful Defense of this Squad. Charge all your Enhancements by 1 (charge). (1 use per turn)",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "chapter",
  },
  {
    id: 19,
    key: 19,
    img: "images/factions/chapter/cards/trap.png",
    cardCount: 3,
    upperAction: "CHARGING",
    upperCost: "1",
    upperActionText: "Charge all your Enhancements by 1 (charge)",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "TRAP",
    lowerActionText:
      "Attach a TRAP token to any enemy model. This model is Blocked until its controller pays 2(crystal) during one of their Active turn to remove the token.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "chapter",
  },
  {
    id: 20,
    key: 20,
    img: "images/factions/chapter/cards/winged_death.png",
    cardCount: 2,
    upperAction: "MOVE AND COMBAT",
    upperCost: "2",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "WINGED DEATH",
    lowerActionText:
      "Flight mode required. Move and then Backstab each enemy model (base_to_base) with the Angel. (1 use per turn)",
    squad: "ANGEL_OF_DEATH",
    count: 0,
    faction: "chapter",
  },
  {
    id: 21,
    key: 21,
    img: "images/factions/demon/cards/horde.png",
    cardCount: 5,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Demon Squad.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "HORDE",
    lowerActionText:
      "Choose 1 enemy model. Choose any Demon models within Movement Range of the target. The Demon models Move to (base_to_base) with this enemy and initiate Combat",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "demon",
  },
  {
    id: 22,
    key: 22,
    img: "images/factions/demon/cards/mist.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Demon Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "MIST",
    lowerActionText:
      "Switch positions of any 2 models on the board map, as long as they have the same base size.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "demon",
  },
  {
    id: 23,
    key: 23,
    img: "images/factions/demon/cards/resurrection.png",
    cardCount: 4,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Demon Squad.",
    lowerCost: "X",
    costType: "crystal",
    lowerAction: "RESURRECTION",
    lowerActionText:
      "Return any number of dead models from 1 Demon Squad to any empty hexes on the board map. Pay 1 (crystal) for each returned model and 1 (crystal) for each of their Endurance tokens you wish to restore",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "demon",
  },
  {
    id: 24,
    key: 24,
    img: "images/factions/demon/cards/rage.png",
    cardCount: 2,
    upperAction: "REPEAT",
    upperCost: "1",
    upperActionText:
      "When a model of this Squad deals any Wound, it may perform a Move and/or a Combat. (1 use per payment) ",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "RAGE",
    lowerActionText:
      "When the Son of Khyber kills an enemy model, refresh the Demon Crystal Pool and the Demon Banner card.",
    squad: "SON_OF_KHYBER",
    count: 0,
    faction: "demon",
  },
  {
    id: 25,
    key: 25,
    img: "images/factions/demon/cards/war_fever.png",
    cardCount: 2,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "WAR FEVER",
    lowerActionText:
      "The Son of Khyber gains +1 INI and +1 DEF for each Endurance token he has lost.",
    squad: "SON_OF_KHYBER",
    count: 0,
    faction: "demon",
  },
  {
    id: 26,
    key: 26,
    img: "images/factions/demon/cards/bloody_whirlwind.png",
    cardCount: 4,
    upperAction: "ATTACK",
    upperCost: "1",
    upperActionText: "A Demon model gain +2 to an ATT roll.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "BLOODY WHIRLWIND",
    lowerActionText:
      "The Son of Khyber may perform one Combat for each Endurance token he has lost.",
    squad: "SON_OF_KHYBER",
    count: 0,
    faction: "demon",
  },
  {
    id: 27,
    key: 27,
    img: "images/factions/demon/cards/energy_source.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Demon Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "ENERGY SOURCE",
    lowerActionText:
      "Use when the Son of Khyber deals a Wound. Restore 1 of his Endurance tokens.",
    squad: "SON_OF_KHYBER",
    count: 0,
    faction: "demon",
  },
  {
    id: 28,
    key: 28,
    img: "images/factions/demon/cards/crystal_aegis.png",
    cardCount: 3,
    upperAction: "DARK POWER",
    upperCost: "0",
    upperActionText:
      "Exchange 1 Demon Endurance token for 2 Temporary Crystals.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "CRYSTAL AEGIS",
    lowerActionText:
      "Attach a CRYSTAL AEGIS token to one of your models within Range 4 of the Master of Elements (or to the Master himself). A model with this token can’t be Wounded. Discard this token at the start of the next opponent’s Passive turn.",
    squad: "MASTER_OF_ELEMENTS",
    count: 0,
    faction: "demon",
  },
  {
    id: 29,
    key: 29,
    img: "images/factions/demon/cards/fireball.png",
    cardCount: 4,
    upperAction: "DARK POWER",
    upperCost: "0",
    upperActionText:
      "Exchange 1 Demon Endurance token for 2 Temporary Crystals.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "FIREBALL",
    lowerActionText:
      "Choose a target Space (Range 3, Line of Fire required). Check all models in this and 1 adjacent Space for Hit (+2 to roll if the Master is in Primal Focus mode). Effect: 1 Wound. Attach a FIRE token to 1 of the affected models.",
    squad: "MASTER_OF_ELEMENTS",
    count: 0,
    faction: "demon",
  },
  {
    id: 30,
    key: 30,
    img: "images/factions/demon/cards/freeze.png",
    cardCount: 4,
    upperAction: "TELEPORT",
    upperCost: "1",
    upperActionText: "Teleport 1 Demon model.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "FREEZE",
    lowerActionText:
      "Attach a FREEZE token to 1 enemy model within Range 3 of the Master of Elements. Effect: INI 0, ATT —, Move —, until the opponent pays 2 during their Active turn to remove the token",
    squad: "MASTER_OF_ELEMENTS",
    count: 0,
    faction: "demon",
  },
  {
    id: 31,
    key: 31,
    img: "images/factions/demon/cards/ice_beam.png",
    cardCount: 4,
    upperAction: "DARK POWER",
    upperCost: "0",
    upperActionText:
      "Exchange 1 Demon Endurance token for 2 Temporary Crystals.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "ICE BEAM",
    lowerActionText:
      "Check all models within a single Line of Fire of this model for Hit (Range unlimited, +2 to roll if Master of Elements is in Primal Focus mode). Effect: 1 Wound. ",
    squad: "MASTER_OF_ELEMENTS",
    count: 0,
    faction: "demon",
  },
  {
    id: 32,
    key: 32,
    img: "images/factions/demon/cards/infection.png",
    cardCount: 3,
    upperAction: "ADDITIONAL WOUND",
    upperCost: "2",
    upperActionText:
      "Models of this Squad deal 1 additional Wound when Wounding. (1 use per combat)",
    lowerCost: "",
    costType: "charge",
    lowerAction: "INFECTION",
    lowerActionText:
      "Use during Combat to attach an INFECTION token to the enemy model (-1 to all Attributes until the end of the game). (1 use per Combat",
    squad: "GOREHOUND",
    count: 0,
    faction: "demon",
  },
  {
    id: 33,
    key: 33,
    img: "images/factions/demon/cards/hijack.png",
    cardCount: 3,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Demon Squad.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "HIJACK",
    lowerActionText:
      "Move the Gorehound and 1 enemy model  with it (both models must remain  throughout the Move, and the enemy model can’t Push). Then, perform a Combat with any Demon models with the Hijacked model. ",
    squad: "GOREHOUND",
    count: 0,
    faction: "demon",
  },
  {
    id: 34,
    key: 34,
    img: "images/factions/demon/cards/shadowzone.png",
    cardCount: 3,
    upperAction: "TELEPORT",
    upperCost: "1",
    upperActionText: "Teleport 1 Demon model.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SHADOWZONE",
    lowerActionText:
      "Remove a Gorehound model from the board map. When you Activate this Gorehound again, place him on any empty hexes.",
    squad: "GOREHOUND",
    count: 0,
    faction: "demon",
  },
  {
    id: 35,
    key: 35,
    img: "images/factions/demon/cards/rush.png",
    cardCount: 3,
    upperAction: "GAIN 1 TEMPORARY CRYSTAL",
    upperCost: "0",
    upperActionText: "",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "RUSH",
    lowerActionText: "Perform a Backstab with 1 Gorehound model.",
    squad: "GOREHOUND",
    count: 0,
    faction: "demon",
  },
  {
    id: 36,
    key: 36,
    img: "images/factions/demon/cards/self-destruct.png",
    cardCount: 2,
    upperAction: "MOVE",
    upperCost: "1",
    upperActionText: "with all models in 1 Demon Squad.",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "SELF-DESTRUCT",
    lowerActionText:
      "Sacrifice 1 Dark Scholar model. Check all models (base_to_base) with it, and all models within the same Space for Hit. Effect: 1 Wound. (1 use per payment)",
    squad: "GOREHOUND",
    count: 0,
    faction: "demon",
  },
  {
    id: 37,
    key: 37,
    img: "images/factions/demon/cards/mind_control.png",
    cardCount: 4,
    upperAction: "GAIN 1 TEMPORARY CRYSTAL",
    upperCost: "0",
    upperActionText: "",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "MIND CONTROL",
    lowerActionText:
      "Ritual mode required. Choose 1 target enemy model within Range 1 of any Dark Scholar. Perform Move with the target (Pushing permitted). Then, it performs a Combat with every other enemy model (base_to_base) with it.",
    squad: "DARK_SCHOLAR",
    count: 0,
    faction: "demon",
  },
  {
    id: 38,
    key: 38,
    img: "images/factions/demon/cards/spectral_grasp.png",
    cardCount: 3,
    upperAction: "TELEPORT",
    upperCost: "1",
    upperActionText: "Teleport 1 Demon model.",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "SPECTRAL GRASP",
    lowerActionText:
      "Ritual mode required. Attach a SPECTRAL GRASP token to 1 enemy model (base_to_base) with a Dark Scholar. Effect: the model is Blocked and gets -1 to all attributes as long as it remains (base_to_base) with any Dark Scholar. Enemy player cannot Push those Dark Scholars.",
    squad: "DARK_SCHOLAR",
    count: 0,
    faction: "demon",
  },
  {
    id: 39,
    key: 39,
    img: "images/factions/demon/cards/faith.png",
    cardCount: 4,
    upperAction: "INITIATIVE",
    upperCost: "1",
    upperActionText: "+2 INI for one Demon INI check",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "FAITH",
    lowerActionText: "Charge 1 of your Enhancements by 3 (charge).",
    squad: "BLOODSMITH",
    count: 0,
    faction: "demon",
  },
  {
    id: 40,
    key: 40,
    img: "images/factions/demon/cards/infernal_power.png",
    cardCount: 4,
    upperAction: "CHARGING",
    upperCost: "1",
    upperActionText: "Charge all your Enhancements by 1 (charge) .",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "INFERNAL POWER",
    lowerActionText: "Change Mode of the Bloodsmith Squad card.",
    squad: "BLOODSMITH",
    count: 0,
    faction: "demon",
  },
  {
    id: 41,
    key: 41,
    img: "images/factions/demon/cards/infernal_rites.png",
    cardCount: 2,
    upperAction: "DARK POWER",
    upperCost: "1",
    upperActionText:
      "Exchange 1 Demon Endurance token for 2 Temporary Crystals. (1 use per turn)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "INFERNAL RITES",
    lowerActionText:
      "After Wounding an enemy, return 1 of your dead models to any empty hexes. (1 use per turn)",
    squad: "BLOODSMITH",
    count: 0,
    faction: "demon",
  },
  {
    id: 42,
    key: 42,
    img: "images/factions/dvergar/cards/exoskeleton.png",
    cardCount: 4,
    upperAction: "REROLL",
    upperCost: "1",
    upperActionText: "(1 use per roll of this Squad)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "EXOSKELETON",
    lowerActionText: "Movement Range is doubled.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 43,
    key: 43,
    img: "images/factions/dvergar/cards/junk.png",
    cardCount: 2,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "JUNK",
    lowerActionText:
      "Use right after this Squad loses an Endurance token. Put this token on the Dvergar Shrine.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 44,
    key: 44,
    img: "images/factions/dvergar/cards/radiostation.png",
    cardCount: 3,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "RADIOSTATION",
    lowerActionText:
      "As long as this Squad has at least 1 model, Dvergar player’s hand limit is increased by 1 and the player draws an additional card each turn. ",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 45,
    key: 45,
    img: "images/factions/dvergar/cards/detonation.png",
    cardCount: 4,
    upperAction: "PROSPECTING",
    upperCost: "1",
    upperActionText:
      "Destroy 1 Dvergar (base_to_base) , then add 1 (base_to_base) to the Dvergar Depleted Pool for each Controlled Crystal Source.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "DETONATION",
    lowerActionText:
      "Use after a Dvergar model is killed - it explodes. Check all models within its Space and all adjacent Spaces for Hit. Add +2 to result. Effect: 1 Wound.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 46,
    key: 46,
    img: "images/factions/dvergar/cards/mechanism.png",
    cardCount: 4,
    upperAction: "JUNK",
    upperCost: "0",
    upperActionText:
      "Use right after Dvergar lose an Endurance token. Put this token on the Dvergar Shrine.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "MECHANISM",
    lowerActionText:
      "Move any number of Enhancements (charge), , and Endurance tokens between the Dvergar Squads (even the dead ones).  ",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 47,
    key: 47,
    img: "images/factions/dvergar/cards/fury.png",
    cardCount: 4,
    upperAction: "REFRESH CRYSTALS",
    upperCost: "4",
    upperActionText: "Discard 1 card to refresh the Dvergar Crystal Pool.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "FURY",
    lowerActionText:
      "Perform Combat with the Avenger against any number of enemy models (base_to_base) . For each enemy killed, earn 1 additional VP.",
    squad: "AVENGER",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 48,
    key: 48,
    img: "images/factions/dvergar/cards/chain_grab.png",
    cardCount: 4,
    upperAction: "SCAVENGERS",
    upperCost: "4",
    upperActionText:
      "Use right after any Dvergar deals any Wound. Charge any 1 Dvergar Enhancement by 3 (charge) .",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "CHAIN GRAB",
    lowerActionText:
      "Choose 1 enemy model within Range 3 of the Avenger and place this model in any empty hexes (base_to_base) with the Avenger.",
    squad: "AVENGER",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 49,
    key: 49,
    img: "images/factions/dvergar/cards/mechanical_shield.png",
    cardCount: 4,
    upperAction: "FEEDBACK",
    upperCost: "1",
    upperActionText:
      "Use right after a Dvergar model is killed or Sacrificed. Charge all Dvergar Enhancements by 2 (charge).",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "MECHANICAL SHIELD",
    lowerActionText:
      "Use right before a DEF roll. The Avenger automatically wins this roll.",
    squad: "AVENGER",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 50,
    key: 50,
    img: "images/factions/dvergar/cards/refined_fuel.png",
    cardCount: 2,
    upperAction: "MOVE",
    upperCost: "1",
    upperActionText: "with all model in this Squad. (1 use per turn)",
    lowerCost: "x",
    costType: "charge",
    lowerAction: "REFINED FUEL",
    lowerActionText:
      "For each 1 (charge) spent, add +2 to a Hit roll of a single Flamethrower action and place 1 additional FIRE token when resolving this action. (1 use per Shot)",
    squad: "FLAME_KEEPER",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 51,
    key: 51,
    img: "images/factions/dvergar/cards/flamethrower.png",
    cardCount: 6,
    upperAction: "JUNK",
    upperCost: "0",
    upperActionText:
      "Use right after Dvergar lose an Endurance token. Put this token on the Dvergar Shrine",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "FLAMETHROWER",
    lowerActionText:
      "Active mode required. Choose 1 Flame Keeper. Check all models within a single Line of Fire in Range 2 for Hit. Effect: 1 Wound. Put a FIRE token on 1 of the affected models.",
    squad: "FLAME_KEEPER",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 52,
    key: 52,
    img: "images/factions/dvergar/cards/meltdown.png",
    cardCount: 4,
    upperAction: "FPROSPECTING",
    upperCost: "1",
    upperActionText:
      "Destroy 1 Dvergar (crystal) , then add 1 (crystal) to the Dvergar Depleted Pool for each Controlled Crystal Source.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "MELTDOWN",
    lowerActionText:
      "Sacrifice 1 Flame Keeper. Check all models on its and adjacent Spaces for Hit, adding 2 to the roll. Effect: 1 Wound.",
    squad: "FLAME_KEEPER",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 53,
    key: 53,
    img: "images/factions/dvergar/cards/energize.png",
    cardCount: 2,
    upperAction: "REROLL",
    upperCost: "1",
    upperActionText: "(1 use per roll of this Squad)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "ENERGIZE",
    lowerActionText:
      "During a Dvergar Harvest, you may restore up to 2 Endurance tokens to this Squad instead of taking 1 (crystal).",
    squad: "ENKINDLED",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 54,
    key: 54,
    img: "images/factions/dvergar/cards/chained_weapon.png",
    cardCount: 4,
    upperAction: "JUNK",
    upperCost: "0",
    upperActionText:
      "Use right after Dvergar lose an Endurance token. Put this token on the Dvergar Shrine.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "CHAINED WEAPON",
    lowerActionText: "Perform one Backstab with an Enkindled model (Range 2).",
    squad: "ENKINDLED",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 55,
    key: 55,
    img: "images/factions/dvergar/cards/risky_tactics.png",
    cardCount: 4,
    upperAction: "DAUNTLESS",
    upperCost: "1",
    upperActionText:
      "Use right after a Dvergar loses a DEF roll in Combat. This model may Counterattack before you apply the Wound.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "RISKY TACTICS",
    lowerActionText:
      "Until end of turn, you gain +2 ATT and +2 INI, but automaticaly lose all DEF rolls. If enemy doesn’t lose any Endurance tokens in any way until end of this turn, the Dvergar faction lose 2 VP.  ",
    squad: "ENKINDLED",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 56,
    key: 56,
    img: "images/factions/dvergar/cards/protection.png",
    cardCount: 2,
    upperAction: "MOVE",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "PROTECTION",
    lowerActionText:
      "All your models (base_to_base) with at least one Unbroken model gain +2 DEF.",
    squad: "UNBROKEN",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 57,
    key: 57,
    img: "images/factions/dvergar/cards/deflect.png",
    cardCount: 5,
    upperAction: "SCAVENGERS",
    upperCost: "1",
    upperActionText:
      "Use right after any Dvergar deals any Wound. Charge any 1 Dvergar Enhancement by 3 (charge).",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "DEFLECT",
    lowerActionText:
      "Vigilance mode required. Use right after an Unbroken is Wounded. Ignore this Wound. Chose 1 enemy  (base_to_base) with this model and deal 1 Wound to him.",
    squad: "UNBROKEN",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 58,
    key: 58,
    img: "images/factions/dvergar/cards/marked_for_death.png",
    cardCount: 4,
    upperAction: "JUNK",
    upperCost: "0",
    upperActionText:
      "Use right after Dvergar lose an Endurance token. Put this token on the Dvergar Shrine.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "MARKED FOR DEATH",
    lowerActionText:
      "Vigilance mode required. Attach a MARKED token to an enemy model (base_to_base) with an Unbroken. Once a MARKED model suffers any Wounds, it suffers 1 additional Wound. Remove the token afterwards. ",
    squad: "UNBROKEN",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 59,
    key: 59,
    img: "images/factions/dvergar/cards/rocket.png",
    cardCount: 2,
    upperAction: "REROLL",
    upperCost: "1",
    upperActionText: "(1 use per roll of this Squad)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "ROCKET",
    lowerActionText:
      "Shooting mode required. Choose any Space (Range 3) and check all models within this Space for Hit. Effect: Distribute 3 Wounds between the affected models. (1 use per payment)",
    squad: "GYROBOT",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 60,
    key: 60,
    img: "images/factions/dvergar/cards/reclaimed_crystals.png",
    cardCount: 4,
    upperAction: "CRYSTAL ENGINE",
    upperCost: "0",
    upperActionText:
      "Use right after a Dvergar model is killed. Add 1 (crystal) to the Dvergar Crystal Pool.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "RECLAIMED CRYSTALS",
    lowerActionText:
      "Use right after a Gyrobot is killed. Add 3 (crystal) to the Dvergar Crystal Pool.",
    squad: "GYROBOT",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 61,
    key: 61,
    img: "images/factions/dvergar/cards/slam.png",
    cardCount: 4,
    upperAction: "ARMOR",
    upperCost: "2",
    upperActionText:
      "Use right before a DEF roll. A Dvergar model automatically wins this roll.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SLAM",
    lowerActionText:
      "Until the end of this turn, every Gyrobot that moves through at least 3 Spaces in straight line and then initiates Combat gains +3 ATT and +3 INI for this single Combat. ",
    squad: "GYROBOT",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 62,
    key: 62,
    img: "images/factions/faceless/cards/hell.png",
    cardCount: 5,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "HELL",
    lowerActionText: "Combat with up to 3 different Faceless Squads.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "faceless",
  },
  {
    id: 63,
    key: 63,
    img: "images/factions/faceless/cards/swarm.png",
    cardCount: 6,
    upperAction: "AUTOPHAGY",
    upperCost: "1",
    upperActionText:
      "Sacrifice 1 Faceless model. Any 1 Faceless Squad with models (base_to_base)  with the Sacrificed model may restore up to 2 of its Endurance tokens.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SWARM",
    lowerActionText: "Move with up to 3 different Faceless Squads.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "faceless",
  },
  {
    id: 64,
    key: 64,
    img: "images/factions/faceless/cards/impale.png",
    cardCount: 2,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per payment)",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "IMPALE",
    lowerActionText:
      "Use before an Attack roll and subtract 2 from its result. If the Attack succeeds, deal 2 Wounds instead of 1. (1 per Combat) ",
    squad: "SHATTERER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 65,
    key: 65,
    img: "images/factions/faceless/cards/craving_for_life.png",
    cardCount: 4,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "CRAVING FOR LIFE",
    lowerActionText:
      "Perform Combat with the Shatterer. Restore 1 of his Endurance tokens for each Wound he deals during this Combat.",
    squad: "SHATTERER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 66,
    key: 66,
    img: "images/factions/faceless/cards/unstoppable.png",
    cardCount: 4,
    upperAction: "AUTOPHAGY",
    upperCost: "1",
    upperActionText:
      "Sacrifice 1 Faceless model. Any 1 Faceless Squad with models (base_to_base) with the Sacrificed model may restore up to 2 of its Endurance tokens.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "UNSTOPPABLE",
    lowerActionText:
      "Sacrifice 1 Faceless model (base_to_base)  with the Shatterer to put 3 UNSTOPPABLE tokens on the Shatterer. At any time, you may remove 1 (or more) of these tokens – each grants a +3 bonus to single roll, an INI check or a Move.",
    squad: "SHATTERER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 67,
    key: 67,
    img: "images/factions/faceless/cards/breeding.png",
    cardCount: 2,
    upperAction: "AUTOPHAGY",
    upperCost: "1",
    upperActionText:
      "Sacrifice 1 Faceless model. Any 1 Faceless Squad with models (base_to_base) with the Sacrificed model may restore up to 2 of its Endurance tokens.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "BREEDING",
    lowerActionText:
      "Bring back 1 dead Berserker model (base_to_base) to any empty hexes  with the Harvester. (1 use per payment)",
    squad: "HARVESTER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 68,
    key: 68,
    img: "images/factions/faceless/cards/absorption.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "ABSORPTION",
    lowerActionText:
      "Use right after a Harvester kills an enemy model. You may play any 1 Evolve card from your hand without paying its cost.",
    squad: "HARVESTER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 69,
    key: 69,
    img: "images/factions/faceless/cards/osmosis.png",
    cardCount: 4,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "OSMOSIS",
    lowerActionText:
      "Automatically deal 1 Wound to the Harvester and to 1 enemy model (base_to_base) with it.",
    squad: "HARVESTER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 70,
    key: 70,
    img: "images/factions/faceless/cards/bladespit.png",
    cardCount: 4,
    upperAction: "REGENERATION",
    upperCost: "2",
    upperActionText: "Restore 1 Endurance token of 1 Faceless Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "BLADESPIT",
    lowerActionText:
      "Target 1 Bladebreeder and 1 enemy model (Range 3, Line of Fire required). Check this enemy for Hit. Effect: 1 Wound. If successful, you may also deal any number of Wounds to the Bladebreeder to deal the same number of additional Wounds to the target.",
    squad: "BLADEBREEDER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 71,
    key: 71,
    img: "images/factions/faceless/cards/lurch.png",
    cardCount: 4,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "BLADEBREEDER",
    lowerActionText:
      "Attach a LURCH token to a target enemy model (base_to_base) with a Bladebreeder. Both the model and the Bladebreeder are Blocked. Deal 1 Wound to the target at the start of each Active Faceless turn. Remove the token once (base_to_base) is broken or the target dies.",
    squad: "BLADEBREEDER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 72,
    key: 72,
    img: "images/factions/faceless/cards/back_to_source.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "BACK TO SOURCE",
    lowerActionText:
      "Attach a SOURCE token to 1 Berserker model. This model cannot take any actions, its ATT becomes “–“, and all its other attributes have a value of 0. As long as this model remains in play, all Faceless Squads receive a bonus (choose 1 of 3 tokens: +2 ATT, +2 DEF, +2 INI). ",
    squad: "BERSERKER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 73,
    key: 73,
    img: "images/factions/faceless/cards/chosen_victim.png",
    cardCount: 5,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Faceless Squad",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "CHOSEN VICTIM",
    lowerActionText:
      "Target 1 enemy model. Any Berserker model (from any Squads) within Movement range of the target may Move (base_to_base)  with it. All these Berserker models may perform Combat with the target.",
    squad: "BERSERKER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 74,
    key: 74,
    img: "images/factions/faceless/cards/power.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "POWER",
    lowerActionText:
      "One Fleshripper performs Combat with all enemies  with it, in any order. During each of these Combats, his ATT is increased by +1 for each enemy model currently  with it. ",
    squad: "FLESHRIPPER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 75,
    key: 75,
    img: "images/factions/faceless/cards/resource.png",
    cardCount: 4,
    upperAction: "REROLL",
    upperCost: "0",
    upperActionText: "Reroll any 1 Faceless roll",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "RESOURCE",
    lowerActionText:
      "Use right after a Fleshripper kills an enemy model. The Faceless faction gain 1 (crystal)",
    squad: "FLESHRIPPER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 76,
    key: 76,
    img: "images/factions/reborn/cards/crawling_jungle.png",
    cardCount: 4,
    upperAction: "WOODWALK",
    upperCost: "0",
    upperActionText:
      "Choose 1 Reborn model (base_to_base) with a Thicket or Thornheart Wood. Reposition it to any empty hexes (base_to_base) with another Thicket or Thornheart Wood.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "CRAWLING JUNGLE",
    lowerActionText: "Move any 3 Thickets up to 1 Space each.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "reborn",
  },
  {
    id: 77,
    key: 77,
    img: "images/factions/reborn/cards/seed.png",
    cardCount: 4,
    upperAction: "SPROUT",
    upperCost: "1",
    upperActionText:
      "Place 1 new Thicket with one other Thicket or Thornheart Wood.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SEED",
    lowerActionText:
      "Use right after an enemy model (base_to_base) dies  with a Thornheart Wood. Replace that Thornheart Wood with",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "reborn",
  },
  {
    id: 78,
    key: 78,
    img: "images/factions/reborn/cards/weakening.png",
    cardCount: 2,
    upperAction: "SPROUT",
    upperCost: "1",
    upperActionText:
      "Place 1 new Thicket with (base_to_base) one other Thicket or Thornheart Wood. (1 use per payment)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "WEAKENING",
    lowerActionText:
      "Place 1 WEAKENING token on this card. (1 per payment) All enemy models (base_to_base) with the Oracle get -1 to all attributes for each WEAKENING token",
    squad: "ORACLE_OF_WRATH",
    count: 0,
    faction: "reborn",
  },
  {
    id: 79,
    key: 79,
    img: "images/factions/reborn/cards/call_of_the_wilds.png",
    cardCount: 4,
    upperAction: "SPROUT",
    upperCost: "1",
    upperActionText:
      "Place 1 new Thicket (base_to_base) with one other Thicket  or Thornheart Wood.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "CALL OF THE WILDS",
    lowerActionText:
      "Attach a CALL token to 1 enemy model within Range 1 of the Oracle. At the beginning of any Reborn Active turn, you may Activate the affected model instead of a Reborn Squad. The model can Move and perform Combat with other enemy models. No player can play any Action cards in this Combat.",
    squad: "ORACLE_OF_WRATH",
    count: 0,
    faction: "reborn",
  },
  {
    id: 80,
    key: 80,
    img: "images/factions/reborn/cards/overgrow.png",
    cardCount: 4,
    upperAction: "PARALYSIS",
    upperCost: "1",
    upperActionText:
      "Attach a PARALYSIS token to 1 enemy model within Range 1 of any Reborn model. The target cannot Move. Remove the token at the start of the opponent’s next Passive turn.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "OVERGROW",
    lowerActionText:
      "Place an OVERGROW token on 1 Crystal Source (base_to_base)  with the Oracle. This Crystal Source becomes a Thornheart Wood.",
    squad: "ORACLE_OF_WRATH",
    count: 0,
    faction: "reborn",
  },
  {
    id: 81,
    key: 81,
    img: "images/factions/reborn/cards/blitz.png",
    cardCount: 2,
    upperAction: "REROLL",
    upperCost: "1",
    upperActionText: "(1 use per roll of this Squad)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "BLITZ",
    lowerActionText:
      "Move Blight Steed. Then, if you initiate Combat, gain +1 ATT for each Space traveled in a straight line towards the enemy model. (1 use per turn)",
    squad: "BLIGHT_STEED",
    count: 0,
    faction: "reborn",
  },
  {
    id: 82,
    key: 82,
    img: "images/factions/reborn/cards/battering_ram.png",
    cardCount: 4,
    upperAction: "WOODWALK",
    upperCost: "0",
    upperActionText:
      "Choose 1 Reborn model (base_to_base) with a Thicket or Thornheart Wood. Reposition it to any empty hexes  (base_to_base) with another Thicket or Thornheart Wood.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "BATTERING RAM",
    lowerActionText:
      "If it moves at least 4 Spaces in a straight line, attach a STUN token to 1 enemy (base_to_base), and then bash this enemy to an adjacent Space. The target gets -3 to all attributes. Remove the token at the start of the opponent’s next Passive turn.",
    squad: "BLIGHT_STEED",
    count: 0,
    faction: "reborn",
  },
  {
    id: 83,
    key: 83,
    img: "images/factions/reborn/cards/wild_charge.png",
    cardCount: 4,
    upperAction: "WOODWALK",
    upperCost: "0",
    upperActionText:
      "Choose 1 Reborn model (base_to_base) with a Thicket or Thornheart Wood. Reposition it to any empty hexes  (base_to_base) with another Thicket or Thornheart Wood.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "WILD CHARGE",
    lowerActionText:
      "Use only after a Blight Steed model has moved at least 4 Spaces in a straight line. Deal X wounds to this Blight Steed and the same amount to 1 enemy model (base_to_base) with it.",
    squad: "BLIGHT_STEED",
    count: 0,
    faction: "reborn",
  },
  {
    id: 84,
    key: 84,
    img: "images/factions/reborn/cards/bloodlust.png",
    cardCount: 2,
    upperAction: "GREEN HAZE",
    upperCost: "1",
    upperActionText:
      "Gain +2 ATT and +2 INI if this model is (base_to_base) with any Thicket. (1 use per Combat)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "BLOODLUST",
    lowerActionText:
      "Use right after a Warbeast suffers any Wound in Combat. Automatically deal 1 Wound to its enemy. (1 use per Combat)",
    squad: "WARBEAST",
    count: 0,
    faction: "reborn",
  },
  {
    id: 85,
    key: 85,
    img: "images/factions/reborn/cards/empowering.png",
    cardCount: 4,
    upperAction: "HERBS",
    upperCost: "1",
    upperActionText:
      "Restore 1 Endurance token to any Reborn model (base_to_base) with any Thicket.",
    lowerCost: "X",
    costType: "crystal",
    lowerAction: "EMPOWERING",
    lowerActionText:
      "Use during Combat. The Warbeast gain +1 to 1 chosen attribute for each 1 (crystal) you spend.",
    squad: "WARBEAST",
    count: 0,
    faction: "reborn",
  },
  {
    id: 86,
    key: 86,
    img: "images/factions/reborn/cards/shapeshift.png",
    cardCount: 4,
    upperAction: "WOODWALK",
    upperCost: "0",
    upperActionText:
      "Choose 1 Reborn model (base_to_base) with a Thicket or Thornheart Wood. Reposition it to any empty hexes  (base_to_base) with another Thicket or Thornheart Wood.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SHAPESHIFT",
    lowerActionText:
      "Exchange positions of 1 Warbeast with any other Reborn model, regardless of their base sizes (no Pushing permitted).",
    squad: "WARBEAST",
    count: 0,
    faction: "reborn",
  },
  {
    id: 87,
    key: 87,
    img: "images/factions/reborn/cards/burrow.png",
    cardCount: 2,
    upperAction: "DEFENSE",
    upperCost: "1",
    upperActionText: "+2 DEF for one roll. (1 use per roll of this Squad)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "BURROW",
    lowerActionText:
      "Perform Combat with 1 Wyrm and switch to Burrowed mode once it’s resolved. (1 use per turn)",
    squad: "WYRM",
    count: 0,
    faction: "reborn",
  },
  {
    id: 88,
    key: 88,
    img: "images/factions/reborn/cards/dragging.png",
    cardCount: 4,
    upperAction: "PHOTOSYNTHESIS",
    upperCost: "1",
    upperActionText:
      "Charge all Enhancements of all Reborn Squads (base_to_base) with any Thicket, by 2 (charge).",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "DRAGGING",
    lowerActionText:
      "Choose a Wyrm and 1 target enemy (Range 2). Place the enemy (base_to_base) with the Wyrm (Pushing permitted).",
    squad: "WYRM",
    count: 0,
    faction: "reborn",
  },
  {
    id: 89,
    key: 89,
    img: "images/factions/reborn/cards/underground_strike.png",
    cardCount: 4,
    upperAction: "PARALYSIS",
    upperCost: "1",
    upperActionText:
      "Attach a PARALYSIS token to 1 enemy model within Range 1 of any Reborn model. The target cannot Move. Remove the token at the start of the opponent’s next Passive turn.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "UNDERGROUND STRIKE",
    lowerActionText:
      "Place a STRIKE token on any empty hex within Range 2 from a Wyrm. At the end of every Reborn Active turn (except this one) you may remove this token and check all models within the same Space for Hit. Effect: 1 Wound.",
    squad: "WYRM",
    count: 0,
    faction: "reborn",
  },
  {
    id: 90,
    key: 90,
    img: "images/factions/reborn/cards/animate_woods.png",
    cardCount: 2,
    upperAction: "DODGE",
    upperCost: "1",
    upperActionText:
      "Use right after this Squad’s model enters Combat. Reposition this model to any adjacent Space and cancel the Combat. (1 use per payment)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "ANIMATE WOODS",
    lowerActionText:
      "Exchange positions of a chosen number of Treemaids with the same number of Thickets. (1 use per turn)",
    squad: "TREEMAID",
    count: 0,
    faction: "reborn",
  },
  {
    id: 91,
    key: 91,
    img: "images/factions/reborn/cards/allure.png",
    cardCount: 4,
    upperAction: "PHOTOSYNTHESIS",
    upperCost: "1",
    upperActionText:
      "Charge all Enhancements of all Reborn Squads (base_to_base) with any Thicket, by 2 (charge).",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "ALLURE",
    lowerActionText:
      "Chose 1 enemy model (base_to_base) with a Treemaid, and perform Move with it.",
    squad: "TREEMAID",
    count: 0,
    faction: "reborn",
  },
  {
    id: 92,
    key: 92,
    img: "images/factions/reborn/cards/lycanthropy.png",
    cardCount: 4,
    upperAction: "SPROUT",
    upperCost: "1",
    upperActionText:
      "Place 1 new Thicket (base_to_base) with one other Thicket or Thornheart Wood.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "LYCANTHROPY",
    lowerActionText:
      "Use when an enemy model is killed within Range 1 of a Treemaid. You may bring back 1 Warbeast dead model in its place. You may also Sacrifice this Treemaid to restore 1 Endurance token to this Warbeast’s Squad.",
    squad: "TREEMAID",
    count: 0,
    faction: "reborn",
  },
  {
    id: 93,
    key: 93,
    img: "images/factions/reborn/cards/sowing.png",
    cardCount: 4,
    upperAction: "HERBS",
    upperCost: "1",
    upperActionText:
      "Restore 1 Endurance token to any Reborn model (base_to_base) with any Thicket.",
    lowerCost: "x",
    costType: "crystal",
    lowerAction: "SOWING",
    lowerActionText:
      "Pay X (crystal) to place X new Thickets within Range 1 of any Treemaids.",
    squad: "TREEMAID",
    count: 0,
    faction: "reborn",
  },
  {
    id: 94,
    key: 94,
    img: "images/factions/reborn/cards/vanishing.png",
    cardCount: 2,
    upperAction: "BACKSTAB",
    upperCost: "1",
    upperActionText: "with all models in this Squad. (1 use per turn)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "VANISHING",
    lowerActionText:
      "As long as a model of this Squad is (base_to_base) with any Thicket or Thornheart Wood, it cannot be Pushed and gains +3 DEF.",
    squad: "ANCIENT_GUARD",
    count: 0,
    faction: "reborn",
  },
  {
    id: 95,
    key: 95,
    img: "images/factions/reborn/cards/poisonous_vines.png",
    cardCount: 4,
    upperAction: "BACKSTAB",
    upperCost: "1",
    upperActionText: "with all models in 1 Reborn Squad",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "POISONOUS VINES",
    lowerActionText:
      "Put a VINES token on the Ancient Guards Squad card. Until the next Reborn Passive turn, all Ancient Guards additionally function as Thickets (though they still still treated as a normal Squad).",
    squad: "ANCIENT_GUARD",
    count: 0,
    faction: "reborn",
  },
  {
    id: 96,
    key: 96,
    img: "images/factions/reborn/cards/rooted.png",
    cardCount: 4,
    upperAction: "HERBS",
    upperCost: "1",
    upperActionText:
      "Restore 1 Endurance token to any Reborn model (base_to_base) with any Thicket.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "ROOTED",
    lowerActionText:
      "Attach a ROOTED token to 1 model (base_to_base) with an Ancient Guard. The target is Blocked as long as it remains (base_to_base) with the Guard, and the Guard cannot be Pushed.",
    squad: "ANCIENT_GUARD",
    count: 0,
    faction: "reborn",
  },
  {
    id: 97,
    key: 97,
    img: "images/factions/rhazack/cards/drill.png",
    cardCount: 3,
    upperAction: "ENHANCED VISION",
    upperCost: "1",
    upperActionText: "Look at the cards in opponent’s hand. (1 use per turn)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "DRILL",
    lowerActionText:
      "Use right after a model of this Squad initiates a Backstab. If the model Wounds its target, deal 1 additional Wound. (1 use per payment) ",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 98,
    key: 98,
    img: "images/factions/rhazack/cards/glide.png",
    cardCount: 2,
    upperAction: "REFRESH CRYSTALS",
    upperCost: "3",
    upperActionText: "(1 use per payment)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "GLIDE",
    lowerActionText: "This Squad may ignore obstacles when Moving.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 99,
    key: 99,
    img: "images/factions/rhazack/cards/self-repair.png",
    cardCount: 2,
    upperAction: "BACKSTAB",
    upperCost: "2",
    upperActionText: "(1 use per payment)",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "SELF-REPAIR",
    lowerActionText:
      "Restore 1 Endurance token to this Squad. (1 use per payment)",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 100,
    key: 100,
    img: "images/factions/rhazack/cards/quickening.png",
    cardCount: 2,
    upperAction: "SOURCE",
    upperCost: "2",
    upperActionText:
      "Put a SOURCE token on 1 Crystal Source. Whenever any Rhazack model Harvests this Source, Rha-Zack gains 2 (crystal) instead of 1. (1 use per payment) ",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "QUICKENING",
    lowerActionText: "+3 INI",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 101,
    key: 101,
    img: "images/factions/rhazack/cards/minelayer.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Rha-Zack Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "MINELAYER",
    lowerActionText:
      "Put up to 3 MINE tokens (2 real mines, 1 dummy) face down on any empty hexes. Whenever any model enters a Space with a MINE token, immediately end the model’s movement, flip and remove the token. Real MINE = check all models within this Space for Hit. Effect: 1 Wound. Dummy MINE = nothing happens.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 102,
    key: 102,
    img: "images/factions/rhazack/cards/time_jump.png",
    cardCount: 3,
    upperAction: "COMMON SELF",
    upperCost: "0",
    upperActionText:
      "1 Rha-Zack model may use INI, DEF or ATT of any other Rha-Zack Squad for 1 Combat or Attribute check.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "TIME JUMP",
    lowerActionText: "Rha-Zack player gets another ACTIVE turn after this one.",
    squad: "ANY_SQUAD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 103,
    key: 103,
    img: "images/factions/rhazack/cards/energy_shields.png",
    cardCount: 2,
    upperAction: "HARVEST",
    upperCost: "2",
    upperActionText:
      "Collect 1(crystal) from each Controlled Crystal Source with a model of this Squad (base_to_base) with it. (1use per turn)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "ENERGY SHIELDS",
    lowerActionText: "+4 Def for 1 roll. (1 use per roll)",
    squad: "INFINITY_ANGEL",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 104,
    key: 104,
    img: "images/factions/rhazack/cards/laser_whip.png",
    cardCount: 2,
    upperAction: "FOCUS",
    upperCost: "1",
    upperActionText:
      "One model of this Squad gains +3 INI for one INI check. (1 use per payment)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "LASER WHIP",
    lowerActionText:
      "Perform a Backstab with this model (Range 2). If you deal a Wound, you may also Move with this model. (1 use per turn)",
    squad: "INFINITY_ANGEL",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 105,
    key: 105,
    img: "images/factions/rhazack/cards/energy_beam.png",
    cardCount: 3,
    upperAction: "BACKSTAB",
    upperCost: "1",
    upperActionText: "with 1 Rha-Zack Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "ENERGY BEAM",
    lowerActionText:
      "Destroy 1 Rha-Zack (crystal). Choose a target Space within Range 3 of the Infinity Angel. Check all enemy models within the target Space for Hit. Effect: 2 Wounds.",
    squad: "INFINITY_ANGEL",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 106,
    key: 106,
    img: "images/factions/rhazack/cards/order.png",
    cardCount: 4,
    upperAction: "ENERGY CLOUD",
    upperCost: "1",
    upperActionText: "Reposition the Rha-Zack Shrine to any empty Space.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "ORDER",
    lowerActionText:
      "Move any Rha-Zack models (base_to_base) with the Infinity Angel.",
    squad: "INFINITY_ANGEL",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 107,
    key: 107,
    img: "images/factions/rhazack/cards/power_swords.png",
    cardCount: 2,
    upperAction: "REROLL",
    upperCost: "1",
    upperActionText: "(1 use per roll of this Squad)",
    lowerCost: "3",
    costType: "charge",
    lowerAction: "POWER SWORDS",
    lowerActionText:
      "All models of this Squad may perform 1 Backstab each before Combat.",
    squad: "STREAM_GUARD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 108,
    key: 108,
    img: "images/factions/rhazack/cards/jump.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Rha-Zack Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "JUMP",
    lowerActionText: "Place this model on any empty hexes.",
    squad: "STREAM_GUARD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 109,
    key: 109,
    img: "images/factions/rhazack/cards/shoot.png",
    cardCount: 5,
    upperAction: "ENERGY CLOUD",
    upperCost: "1",
    upperActionText: "Reposition the Rha-Zack Shrine to any empty Space.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "SHOOT",
    lowerActionText:
      "Guard mode required. Choose a Stream Guard and a target Space (Range 4). Check all models within the target Space for Hit. Effect: 1 Wound.",
    squad: "STREAM_GUARD",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 110,
    key: 110,
    img: "images/factions/rhazack/cards/lightning.png",
    cardCount: 4,
    upperAction: "COMMON SELF",
    upperCost: "0",
    upperActionText:
      "1 Rha-Zack model may use INI, DEF or ATT of any other Rha-Zack Squad for 1 Combat or Attribute check.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "LIGHTNING",
    lowerActionText:
      "Energy mode required. Choose a Storm Bringer. Check all enemy models (Range 1) for Hit. Effect: 1 Wound.",
    squad: "STORM_BRINGER",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 111,
    key: 111,
    img: "images/factions/rhazack/cards/ray.png",
    cardCount: 6,
    upperAction: "ENERGY CLOUD",
    upperCost: "1",
    upperActionText: "Reposition the Rha-Zack Shrine to any empty Space.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "RAY",
    lowerActionText:
      "Energy mode required. Check all enemy models between 2 Storm Bringer models or between Storm Bringer and Energy Cloud for Hit. Effect: 1 Wound.",
    squad: "STORM_BRINGER",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 112,
    key: 112,
    img: "images/factions/rhazack/cards/shock.png",
    cardCount: 4,
    upperAction: "CHARGE",
    upperCost: "1",
    upperActionText: "Charge allCharge all your Enhancements by 1 (charge).\n",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SHOCK",
    lowerActionText:
      "Energy mode required. Choose 1 target model (Range 2) and attach a SHOCK token to the target.    This model gets -2 to INI, ATT and DEF. The Opponent may pay 2 (crystal) during his Active turn to remove this token. ",
    squad: "STORM_BRINGER",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 113,
    key: 113,
    img: "images/factions/rhazack/cards/friction.png",
    cardCount: 5,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all mowith all models in 1 Rha-Zack Squad.\n",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "FRICTION",
    lowerActionText:
      "Move with 1 Surfer model in a straight line. If the model Moved through at least 6 Spaces, Charge 1 chosen Rha-Zack Enhancement by 3 (charge).",
    squad: "STREAM_SURFER",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 114,
    key: 114,
    img: "images/factions/rhazack/cards/surf.png",
    cardCount: 5,
    upperAction: "ENERGY CLOUD",
    upperCost: "1",
    upperActionText: "Reposition the Rha-Zack Shrine to any empty Space.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SURF",
    lowerActionText:
      "Move with 1 Surfer model in a straight line. If it moved through at least 4 Spaces, Backstab an enemy model (base_to_base).",
    squad: "STREAM_SURFER",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 115,
    key: 115,
    img: "images/factions/rhazack/cards/shadowing.png",
    cardCount: 2,
    upperAction: "FOCUS",
    upperCost: "1",
    upperActionText:
      "One model of this Squad gains +3 INI for one INI check. (1 use per payment)",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "SHADOWING",
    lowerActionText:
      "In each Rha-Zack Active turn, you may Activate this Squad in addition to your regular Activation.",
    squad: "SOURCE_BLADE",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 116,
    key: 116,
    img: "images/factions/rhazack/cards/energy_transfer.png",
    cardCount: 4,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all in 1 Rha-Zack Squad.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "ENERGY TRANSFER",
    lowerActionText:
      "Use right after a Source Blade model Wounds an enemy. Instead of dealing any Wounds, you may take 1(crystal) from the opponent and add it to the Rha-Zack Depleted Pool.",
    squad: "SOURCE_BLADE",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 117,
    key: 117,
    img: "images/factions/rhazack/cards/source_blade.png",
    cardCount: 4,
    upperAction: "FORESIGHT",
    upperCost: "0",
    upperActionText:
      "Shuffle up to 3 Rha-Zack cards from your hand back to the Rha-Zack deck to draw the same number of cards",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "SOURCE BLADE",
    lowerActionText:
      "Use when a Source Blade is (base_to_base) with a Crystal Source. Deal 1 Wound to a chosen enemy model (Range 1).",
    squad: "SOURCE_BLADE",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 118,
    key: 118,
    img: "images/factions/chapter/cards/dying_breath.png",
    cardCount: 3,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Chapter Squad",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "DYING BREATH",
    lowerActionText:
      "Choose 1 Holy Rider model and perform Combat with it. In Combat, this model gains +2 to INI, ATT and DEF. This model deals 1 additional Wound when Wounding. If the HOly Rider was in Charge Mode, it gains a +3 to INI, ATT and DEF instead. After Combat, kill this Holy Rider model.",
    squad: "HOLY_RIDERS",
    count: 0,
    faction: "chapter",
  },
  {
    id: 119,
    key: 119,
    img: "images/factions/chapter/cards/winged_charge.png",
    cardCount: 1,
    upperAction: "REINFORCEMENTS",
    upperCost: "1",
    upperActionText:
      "Place tyahe Holy Riders Squad in any 1 Space on the board's edge.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "WINGED CHARGE",
    lowerActionText:
      "Place your Holy Riders in any 1 Space on the board. You can Push any model, except models (base_to_base) with a Cyrstal Source. Check each Pushed model for Hit. Effect: 1 Wound",
    squad: "HOLY_RIDERS",
    count: 0,
    faction: "chapter",
    ignoreLimit: true,
  },
  {
    id: 120,
    key: 120,
    img: "images/factions/chapter/cards/trample.png",
    cardCount: 3,
    upperAction: "INSPIRE",
    upperCost: "1",
    upperActionText:
      "Add +1 to ATT and INI for each Chapter model (base_to_base) (for this Combat only).",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "TRAMPLE",
    lowerActionText:
      "Move your Holy Rider model. Choose 1 enemy model on any Space crossed by the Holy Rider, except the last. Check that model for Hit. Effect: 1 Wound.",
    squad: "HOLY_RIDERS",
    count: 0,
    faction: "chapter",
  },
  {
    id: 121,
    key: 121,
    img: "images/factions/chapter/cards/gallop.png",
    cardCount: 1,
    upperAction: "HASTE",
    upperCost: "1",
    upperActionText: "Double this Squad MOV value for this Move Action.",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "GALLOP",
    lowerActionText:
      "You can Push any model regardless of their base size. You cannot Push a model (base_to_base) with a Crystal Source or end this Move (base_to_base) with a Crystal Source.",
    squad: "HOLY_RIDERS",
    count: 0,
    faction: "chapter",
    ignoreLimit: true,
  },
  {
    id: 122,
    key: 122,
    img: "images/factions/demon/cards/appearance.png",
    cardCount: 1,
    upperAction: "CANTRIP",
    upperCost: "1",
    upperActionText:
      "Place the Succubus Squad in Illusions Mode (base_to_base) with any 1 chosen enemy model.",
    lowerCost: "3",
    costType: "crystal",
    lowerAction: "APPEARANCE",
    lowerActionText:
      "Place the Succubus Squad in Basic Mode anywhere on the board.",
    squad: "SUCCUBUS",
    count: 0,
    faction: "demon",
    ignoreLimit: true,
  },
  {
    id: 123,
    key: 123,
    img: "images/factions/demon/cards/venomous_kiss.png",
    cardCount: 1,
    upperAction: "TRICKERY",
    upperCost: "1",
    upperActionText: "CHange Mode of the Succubus Squad.",
    lowerCost: "-",
    costType: "charge",
    lowerAction: "VENOMOUS KISS",
    lowerActionText:
      "Enemy models (base_to_base) with any Succubus have -1 INI and DEF. If the Succubus Squad is in Illusions Mode, the enemy models (base_to_base) have -1 to all Attributes instead.",
    squad: "SUCCUBUS",
    count: 0,
    faction: "demon",
    ignoreLimit: true,
  },
  {
    id: 124,
    key: 124,
    img: "images/factions/demon/cards/apparitions.png",
    cardCount: 3,
    upperAction: "DARK POWER",
    upperCost: "0",
    upperActionText:
      "Exchange 1 Demon Endurance token for 2 Temporary Crystals.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "APPARITIONS",
    lowerActionText:
      "When enemy has declared Combat against a Succubus (base_to_base), Teleport this Succubus away.",
    squad: "SUCCUBUS",
    count: 0,
    faction: "demon",
  },
  {
    id: 125,
    key: 125,
    img: "images/factions/demon/cards/soul_steal.png",
    cardCount: 3,
    upperAction: "CHARGING",
    upperCost: "1",
    upperActionText: "Charge all of your attached Enhancements by 1 (charge) .",
    lowerCost: "3",
    costType: "crystal",
    lowerAction: "SOUL STEAL",
    lowerActionText:
      "Choose 1 enemy model (base_to_base) with any Succubus and 1 of your models within Range 1 of this Succubus. Move your chosen model (base_to_base) with the chosen model (base_to_base) with the chosen enemy odel and perform Combat with it.",
    squad: "SUCCUBUS",
    count: 0,
    faction: "demon",
  },
  {
    id: 126,
    key: 126,
    img: "images/factions/dvergar/cards/subterran_strike.png",
    cardCount: 1,
    upperAction: "EMERGE",
    upperCost: "1",
    upperActionText:
      "Place the Drillers (base_to_base) with each other on any hexes on the board.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "SUBTERRAN STRIKE",
    lowerActionText:
      "Place the Drillers in any hexes on the board in any Mode. You can Push any model regardless of their base size.",
    squad: "DRILLERS",
    count: null,
    faction: "dvergar",
    ignoreLimit: true,
  },
  {
    id: 127,
    key: 127,
    img: "images/factions/dvergar/cards/puncture.png",
    cardCount: 1,
    upperAction: "OVERHEAT",
    upperCost: "1",
    upperActionText:
      "One Driller model gain +2 to an ATT roll. (1 use per payment).",
    lowerCost: "-",
    costType: "charge",
    lowerAction: "PUNCTURE",
    lowerActionText:
      "Only in Drill Mode. WHen you Attack models with a base of 3 hexes or lager, the Driller deals 1 aditional Wound when Wounding.",
    squad: "DRILLERS",
    count: null,
    faction: "dvergar",
    ignoreLimit: true,
  },
  {
    id: 128,
    key: 128,
    img: "images/factions/dvergar/cards/dynamite.png",
    cardCount: 3,
    upperAction: "CRYSTAL REPAIRS",
    upperCost: "0",
    upperActionText:
      "Destroy any number of (crystal) from the Dvergar Cyrstal Pool to restore the same number of Endurance tokens of 1 chosen Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "DYNAMITE",
    lowerActionText:
      "Choose 1 Crystal Source withing Range 1 of a Driller model. Check each model (yours or enemy's) (base_to_base) with it for Hit. Effect: 1 Wound",
    squad: "DRILLERS",
    count: null,
    faction: "dvergar",
  },
  {
    id: 129,
    key: 129,
    img: "images/factions/dvergar/cards/crystal_breaker.png",
    cardCount: 3,
    upperAction: "REFRESH CRYSTALS",
    upperCost: "1",
    upperActionText: "Discard 1 card to refresh the Dvergar Crystal Pool.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "CRYSTAL BREAKER",
    lowerActionText:
      "Only in Drill Mode. Destroy 1 Dvergar (crystal). You may remove from the board 1 Crystal Source (base_to_base) with a Driller model to add 2(crystal) to the Dvergar Depleted Crystal Pool.",
    squad: "DRILLERS",
    count: null,
    faction: "dvergar",
  },
  {
    id: 130,
    key: 130,
    img: "images/factions/faceless/cards/chrysalis.png",
    cardCount: 1,
    upperAction: "PHOTOTAXIS",
    upperCost: "1",
    upperActionText:
      "Place the Anathema (base_to_base) with any Crystal Source.",
    lowerCost: "3",
    costType: "crystal",
    lowerAction: "CHRYSALIS",
    lowerActionText:
      "Place the Anathema anywhere on the board, in any Mode. You can Push. THe Anathema starts with 2 ANATHEMA tokens.",
    squad: "ANATHEMA",
    count: null,
    faction: "faceless",
    ignoreLimit: true,
  },
  {
    id: 131,
    key: 131,
    img: "images/factions/faceless/cards/abomination.png",
    cardCount: 1,
    upperAction: "RAPID MUTATION",
    upperCost: "-",
    upperActionText:
      "At the beginning of our Active turn you may Change Mode of the Anathema Squad card.",
    lowerCost: "-",
    costType: "charge",
    lowerAction: "ABOMINATION",
    lowerActionText:
      "Only in Basic Mode. At the end of your Active turn, the Anathema gets +1 ANATHEMA token.",
    squad: "ANATHEMA",
    count: null,
    faction: "faceless",
    ignoreLimit: true,
  },
  {
    id: 132,
    key: 132,
    img: "images/factions/faceless/cards/whack.png",
    cardCount: 3,
    upperAction: "REROLL",
    upperCost: "1",
    upperActionText: "any 1 Faceless roll.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "WHACK",
    lowerActionText:
      "Only in Basic Mode. Choose 1 enemy model (base_to_base) with the Anathema and Move it by 1 Space. Check chosen model for Hit. Ad the number of ANATHEMA tokens to the roll. Effect: 1 Wound",
    squad: "ANATHEMA",
    count: null,
    faction: "faceless",
  },
  {
    id: 133,
    key: 133,
    img: "images/factions/faceless/cards/tentacles.png",
    cardCount: 3,
    upperAction: "AUTOPHAGY",
    upperCost: "1",
    upperActionText:
      "Sacrifice 1 Faceless model. Any 1 Faceless Squad with models (base_to_base) with the Sacrificed model may restore up to 2 of its Endurance tokens.",
    lowerCost: "x",
    costType: "crystal",
    lowerAction: "TENTACLES",
    lowerActionText:
      "Teleport  models (yours or enemy) within Range X and with a base smaller than 3 hexes (base_to_base) with the Anathema. They cannot Push. X = Number of ANATHEMA tokens.",
    squad: "ANATHEMA",
    count: null,
    faction: "faceless",
  },
  {
    id: 134,
    key: 134,
    img: "images/factions/reborn/cards/manifestation.png",
    cardCount: 1,
    upperAction: "SYMBIOSIS",
    upperCost: "1",
    upperActionText:
      "Place the Forest Wraith (base_to_base) with the Reborn SHrine and place 1 Thicket (base_to_base) with the Forest Wraith.",
    lowerCost: "3",
    costType: "crystal",
    lowerAction: "MANIFESTATION",
    lowerActionText:
      "Place the Forest Wraith in Wraith Mode in any empty hexes on the board and perform a Backstab with it. Until the end of this turn, the Forest Wraith gains +1 ATT for reach THicket connected to the Shrine (base_to_base) with it",
    squad: "FOREST_WRAITH",
    count: null,
    faction: "reborn",
    ignoreLimit: true,
  },
  {
    id: 135,
    key: 135,
    img: "images/factions/reborn/cards/spikes_explosion.png",
    cardCount: 3,
    upperAction: "HERBS",
    upperCost: "1",
    upperActionText:
      "Restore 1 Endurance token to any Reborn model (base_to_base) with any Thicket.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "SPIKES EXPLOSION",
    lowerActionText:
      "Deal 1 Wound to the Forest Wraith. Check all models (base_to_base) with the Forest Wraith for Hit. Effect: 1 Wound.",
    squad: "FOREST_WRAITH",
    count: null,
    faction: "reborn",
  },
  {
    id: 136,
    key: 136,
    img: "images/factions/reborn/cards/thorn_beast.png",
    cardCount: 1,
    upperAction: "ADDITIONAL WOUND",
    upperCost: "1",
    upperActionText:
      "When the Forest Wraith is (base_to_base) with the Reborn Shrine, it deals 1 additional Wound when Wounding.",
    lowerCost: "-",
    costType: "charge",
    lowerAction: "THORN BEAST",
    lowerActionText:
      "When the Forest Wraith kills a model, you may place a Thicket (base_to_base) with the killed model.",
    squad: "FOREST_WRAITH",
    count: null,
    faction: "reborn",
    ignoreLimit: true,
  },
  {
    id: 137,
    key: 137,
    img: "images/factions/reborn/cards/forest_elemental.png",
    cardCount: 3,
    upperAction: "PHOTOSYNTHESIS",
    upperCost: "1",
    upperActionText:
      "Charge all Enhancements of all Reborn Squads (base_to_base) with any Thicket, by 2 (charge).",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "FOREST ELEMENTAL",
    lowerActionText:
      "Remove up to 3 THickets from anywhere on the board. Place up to the same number of THickets (base_to_base) with the Forest Wraith.",
    squad: "FOREST_WRAITH",
    count: null,
    faction: "reborn",
  },
  {
    id: 138,
    key: 138,
    img: "images/factions/rhazack/cards/from_the_skies.png",
    cardCount: 1,
    upperAction: "DESCEND",
    upperCost: "1",
    upperActionText: "Place he Jumpsuit in any empty hexes.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "FROM THE SKIES",
    lowerActionText:
      "Place the Jumpsuit in any empty hexes. Check all models (yours and enemy) (base_to_base) with Jumpsuit for Hit. Effect: 1 Wound.",
    squad: "JUMPSUIT",
    count: null,
    faction: "rhazack",
    ignoreLimit: true,
  },
  {
    id: 139,
    key: 139,
    img: "images/factions/rhazack/cards/lightning_strike.png",
    cardCount: 1,
    upperAction: "TEMPORARY SETBACK",
    upperCost: "1",
    upperActionText:
      "When Wounded, you may lose VP instead of Endurance tokens.",
    lowerCost: "2",
    costType: "charge",
    lowerAction: "LIGHTNING STRIKE",
    lowerActionText:
      "Only in Jump Mode. You may Move (Range 3, ignoring Obstacles). Any moel in the way is Pushed, regardless of its base size. Check all Pushed models for Hit. Effect: 1 Wound. (Once per turn)",
    squad: "JUMPSUIT",
    count: null,
    faction: "rhazack",
    ignoreLimit: true,
  },
  {
    id: 140,
    key: 140,
    img: "images/factions/rhazack/cards/barrier.png",
    cardCount: 3,
    upperAction: "ENERGY CLOUD",
    upperCost: "1",
    upperActionText: "Reposition the Rha-Zack Shrine to any empty Space.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "BARRIER",
    lowerActionText:
      "Place 3 BARRIER tokens on 3 empty Spaces withing Range 3 of the Jumpsuit. No model may enter these Spaces, unless it pays 1 (crystal). You may remove these tokens at any moment during your Active Turn.",
    squad: "JUMPSUIT",
    count: null,
    faction: "rhazack",
  },
  {
    id: 141,
    key: 141,
    img: "images/factions/rhazack/cards/gravitron_punch.png",
    cardCount: 3,
    upperAction: "CHARGE",
    upperCost: "1",
    upperActionText: "Charge all of your attached Enhancements by 1 (charge) .",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "GRAVITRON PUNCH",
    lowerActionText:
      "Backstab 1 chosen enemy model (base_to_base) with the Jumpsuit. THen, Move the chosen model by 3 Spacs. You can Push.",
    squad: "JUMPSUIT",
    count: null,
    faction: "rhazack",
  },
  {
    id: 142,
    key: 142,
    img: "images/factions/darkness/cards/dark_reflection.png",
    cardCount: 1,
    upperAction: "DECEIT",
    upperCost: "1",
    upperActionText:
      "Place Prime Shadow on any Space withing Range 1 (can't Push).",
    lowerCost: "0",
    costType: "charge",
    lowerAction: "DARK REFLECTION",
    lowerActionText:
      "Only in Dark Mode. ALl enemy models (base_to_base) with Prime Shadow replace their INI, ATT, DEF Attributes with those of the Prime Shadow.",
    squad: "PRIME_SHADOW",
    count: null,
    faction: "darkness",
    ignoreLimit: true,
  },
  {
    id: 143,
    key: 143,
    img: "images/factions/darkness/cards/darkness_herald.png",
    cardCount: 1,
    upperAction: "BIRTH",
    upperCost: "1",
    upperActionText:
      "Place the Prime Shadow (base_to_base) with the Darkness Shrine",
    lowerCost: "3",
    costType: "crystal",
    lowerAction: "DARKNESS HERALD",
    lowerActionText:
      "Place the Prime Shadow (base_to_base) with any enemy model. Its Squad card may be in any Mode. You gain 1 (darkness) for each enemy model (base_to_base) with the Prime Shadow.",
    squad: "PRIME_SHADOW",
    count: null,
    faction: "darkness",
    ignoreLimit: true,
  },
  {
    id: 144,
    key: 144,
    img: "images/factions/darkness/cards/leech.png",
    cardCount: 3,
    upperAction: "SHADOW WAY",
    upperCost: "0",
    upperActionText:
      "Place 1 chosen Darkness model (base_to_base) with the Additional Darkness Shrine token.",
    lowerCost: "0",
    costType: "darkness",
    lowerAction: "LEECH",
    lowerActionText:
      "Only in Dark Mode. Choose 1 enemy model (base_to_base) with the Prime Shadow. Add an amount of Darkness tokens to your Pool equal to the number of Endurance tokens on the chosen model Squad card.",
    squad: "PRIME_SHADOW",
    count: null,
    faction: "darkness",
  },
  {
    id: 145,
    key: 145,
    img: "images/factions/darkness/cards/well_of_darkness.png",
    cardCount: 3,
    upperAction: "DARK SURGE",
    upperCost: "0",
    upperActionText: "Spawn (darkness)",
    lowerCost: "2",
    costType: "darkness",
    lowerAction: "WELL OF DARKNESS",
    lowerActionText:
      "Teleport any number of models (yours or enemy) within Range 1 (base_to_base) with the Darkness Shrine. Teleported models cannot Push.",
    squad: "PRIME_SHADOW",
    count: null,
    faction: "darkness",
  },
  {
    id: 146,
    key: 146,
    img: "images/factions/darkness/cards/anger.png",
    cardCount: 4,
    upperAction: "DARK BLOOD",
    upperCost: "0",
    upperActionText: "When you deal a Wound, add 2 (darkness) to your Pool",
    lowerCost: "0",
    costType: "darkness",
    lowerAction: "ANGER",
    lowerActionText:
      "When a Slaughter Crow model deals a Wound, refresh your Crystal Pool. This Slaughter Crow model may perform an additional Combat.",
    squad: "SLAUGHTER_CROWS",
    count: null,
    faction: "darkness",
  },
  {
    id: 147,
    key: 147,
    img: "images/factions/darkness/cards/hunger.png",
    cardCount: 4,
    upperAction: "PORTAL",
    upperCost: "1",
    upperActionText:
      "Reposition the Additional Darkness Shrine token on a Crystal Source. ",
    lowerCost: "0",
    costType: "darkness",
    lowerAction: "HUNGER",
    lowerActionText:
      "Automatically deal 1 Wound to a Slaughter Crow and to 1 enemy model (base_to_base) with it.",
    squad: "SLAUGHTER_CROWS",
    count: null,
    faction: "darkness",
  },
  {
    id: 148,
    key: 148,
    img: "images/factions/darkness/cards/murder_of_crows.png",
    cardCount: 4,
    upperAction: "DARK SURGE",
    upperCost: "0",
    upperActionText: "Spawn (darkness)",
    lowerCost: "x",
    costType: "darkness",
    lowerAction: "MURDER OF CROWS",
    lowerActionText:
      "Shift the Slaughter Crow Squad card to its Basic Mode. Remove all Slaughter Crow models from the board and place them on their Squad card. Next time you Activate this Squad, place all models (including dead ones) anywhere on the board. Cost: Number of Crows on the board.",
    squad: "SLAUGHTER_CROWS",
    count: null,
    faction: "darkness",
  },
  {
    id: 149,
    key: 149,
    img: "images/factions/darkness/cards/poison_kiss.png",
    cardCount: 4,
    upperAction: "DARK SURGE",
    upperCost: "0",
    upperActionText: "Spawn (darkness)",
    lowerCost: "2",
    costType: "darkness",
    lowerAction: "POISON KISS",
    lowerActionText:
      "Range 2, Choose 1 target. Check for Hit. Effect: Place a Poison token on the target enemy model.",
    squad: "IMPOSTOR",
    count: null,
    faction: "darkness",
  },
  {
    id: 150,
    key: 150,
    img: "images/factions/darkness/cards/chaos_wisdom.png",
    cardCount: 4,
    upperAction: "PORTAL",
    upperCost: "1",
    upperActionText:
      "Reposition the Additional Darkness Shrine token on a Crystal Source.",
    lowerCost: "2",
    costType: "darkness",
    lowerAction: "CHAOS WISDOM",
    lowerActionText:
      "Target 1 of  your models withing Range 1. Effect: put a WISDOM token on this model's Squad card.",
    squad: "IMPOSTOR",
    count: null,
    faction: "darkness",
  },
  {
    id: 151,
    key: 151,
    img: "images/factions/darkness/cards/mesmerizing.png",
    cardCount: 4,
    upperAction: "GATHERING",
    upperCost: "1",
    upperActionText:
      "Collect (darkness) on any 1 Space occupied by a Darkness model.",
    lowerCost: "1",
    costType: "darkness",
    lowerAction: "MESMERIZING",
    lowerActionText:
      "Choose 1 model(yours or enemy) within Range 2 of an Impostor. Perform a Move Action with the chosen model.",
    squad: "IMPOSTOR",
    count: null,
    faction: "darkness",
  },
  {
    id: 152,
    key: 152,
    img: "images/factions/darkness/cards/living_death.png",
    cardCount: 4,
    upperAction: "PORTAL",
    upperCost: "1",
    upperActionText:
      "Reposition the Additional Darkness Shrine token on a Crystal Source.",
    lowerCost: "1",
    costType: "darkness",
    lowerAction: "LIVING DEATH",
    lowerActionText:
      "All enemies without any Endurance tokens and (base_to_base) with the Bell Crow are killed.",
    squad: "BELL_CROW",
    count: null,
    faction: "darkness",
  },
  {
    id: 153,
    key: 153,
    img: "images/factions/darkness/cards/bellower.png",
    cardCount: 4,
    upperAction: "DARK SURGE",
    upperCost: "0",
    upperActionText: "Spawn (darkness)",
    lowerCost: "3",
    costType: "darkness",
    lowerAction: "BELLOWER",
    lowerActionText:
      "Perform Combat with the Bell Crow. Bell Crow has +2 INI and ATT. If it wounds, remove ALL Endurance tokens from the oppsoing model's Squad card.",
    squad: "BELL_CROW",
    count: null,
    faction: "darkness",
  },
  {
    id: 154,
    key: 154,
    img: "images/factions/darkness/cards/ominous_peal.png",
    cardCount: 4,
    upperAction: "REPULSE",
    upperCost: "1",
    upperActionText: "Reposition any Shrine 1 Space",
    lowerCost: "1",
    costType: "darkness",
    lowerAction: "OMINOUS PEAL",
    lowerActionText:
      "Perform a Move Action with the Bell Crow. Then choose any Darkness models within Range 1. These models gain +2 ATT, have a base DEF of 0 and may perform Combat.",
    squad: "BELL_CROW",
    count: null,
    faction: "darkness",
  },
  {
    id: 155,
    key: 155,
    img: "images/factions/darkness/cards/doom.png",
    cardCount: 4,
    upperAction: "DARK TIDE",
    upperCost: "1",
    upperActionText:
      "Place 1 (darkness) (base_to_base) with the Darkness Shrine.",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "DOOM",
    lowerActionText:
      "When on the board and Checking for Hit, the Abyssal Empress gains +1 for each Void Spectre (base_to_base) with it.",
    squad: "VOID_SPECTER",
    count: null,
    faction: "darkness",
  },
  {
    id: 156,
    key: 156,
    img: "images/factions/darkness/cards/black_mark.png",
    cardCount: 4,
    upperAction: "GATHERING",
    upperCost: "1",
    upperActionText:
      "Collect (darkness) on any 1 Space occupied by a Darkness model.",
    lowerCost: "2",
    costType: "darkness",
    lowerAction: "BLACK MARK",
    lowerActionText: "Put a BLACK MARK token on any enemy model.",
    squad: "VOID_SPECTER",
    count: null,
    faction: "darkness",
  },
  {
    id: 157,
    key: 157,
    img: "images/factions/darkness/cards/nightmare.png",
    cardCount: 4,
    upperAction: "PORTAL",
    upperCost: "1",
    upperActionText:
      "Reposition the Additional Darkness Shrine token on a Crystal Source.",
    lowerCost: "1",
    costType: "darkness",
    lowerAction: "NIGHTMARE",
    lowerActionText: "Put a BLACK MARK token on any of your models.",
    squad: "VOID_SPECTER",
    count: null,
    faction: "darkness",
  },
  {
    id: 158,
    key: 158,
    img: "images/factions/darkness/cards/cauterisation.png",
    cardCount: 4,
    upperAction: "PORTAL",
    upperCost: "1",
    upperActionText:
      "Reposition the Additional Darkness Shrine token on a Crystal Source.",
    lowerCost: "1",
    costType: "darkness",
    lowerAction: "CAUTERISATION",
    lowerActionText:
      "Put X (darkness) and restore X Endurance tokens of 1 chosen Squad.",
    squad: "VOID_SPECTER",
    count: null,
    faction: "darkness",
  },
  {
    id: 159,
    key: 159,
    img: "images/factions/darkness/cards/darkness_resurgent.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "Move with all models in 1 Darkness Squad.",
    lowerCost: "x",
    costType: "darkness",
    lowerAction: "DARKNESS RESURGENT",
    lowerActionText:
      "Return dead models from chosen Squad into play by paying X (darkness) where X the Squad's starting Endurance value. Return models are place (base_to_base) with the Darkness Shrine.",
    squad: "VOID_SPECTER",
    count: null,
    faction: "darkness",
  },
  {
    id: 160,
    key: 160,
    img: "images/factions/darkness/cards/metamorphosis.png",
    cardCount: 4,
    upperAction: "PORTAL",
    upperCost: "1",
    upperActionText:
      "Reposition the Additional Darkness Shrine token on a Crystal Source.",
    lowerCost: "1",
    costType: "darkness",
    lowerAction: "METAMORPHOSIS",
    lowerActionText:
      "Sacrifice 1 Shadow model and place a Slaughter Crow model (if there is one available) on the same hex.",
    squad: "SHADOW",
    count: null,
    faction: "darkness",
  },
  {
    id: 161,
    key: 161,
    img: "images/factions/darkness/cards/shadowy_tendrils.png",
    cardCount: 4,
    upperAction: "DARK SURGE",
    upperCost: "0",
    upperActionText: "Spawn (darkness)",
    lowerCost: "1",
    costType: "darkness",
    lowerAction: "SHADOWY TENDRILS",
    lowerActionText:
      "Attach a SHADOW GRASP token to 1 enemy model (base_to_base) with a Shadow model. Effect: the model is Blocked and has ATT - as long as it remains (base_to_base) with any Shadow. Enemy models cannot Push these Shadows.",
    squad: "SHADOW",
    count: null,
    faction: "darkness",
  },
  {
    id: 162,
    key: 162,
    img: "images/factions/darkness/cards/emptiness.png",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "Move with all models in 1 Darkness Squad.",
    lowerCost: "0",
    costType: "darkness",
    lowerAction: "EMPTINESS",
    lowerActionText:
      "When a Shadow is killed, add 1 (darkness) to your Pool. This Shadow model is placed back on any empty hex of the board.",
    squad: "SHADOW",
    count: null,
    faction: "darkness",
  },

  {
    id: 163,
    key: 163,
    img: "images/factions/chapter/cards/discharge.jpg",
    cardCount: 1,
    upperAction: "HEAVY ARMOR",
    upperCost: "0",
    upperActionText:
      "The War Golem gains +1 DEF and -1 INI for each lost Endurance token.",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "DISCHARGE",
    lowerActionText: "Put a DISCHARGE Token on 1 (base_to_base) enemy model.",
    squad: "WAR_GOLEM",
    count: 0,
    faction: "chapter",
    ignoreLimit: true,
  },

  {
    id: 164,
    key: 164,
    img: "images/factions/chapter/cards/smash.jpg",
    cardCount: 3,
    upperAction: "RECHARGE",
    upperCost: "0",
    upperActionText:
      "Remove all DISCHARGE tokens from all enemy models on the board and refresh the same number of Crystals in the Chapter Crystal Pool.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "SMASH",
    lowerActionText:
      "Perform a Move Action, then a Combat Action with the War Golem. Remove all DISCHARGE tokens from the targeted enemy model. For each DISCHARGE token removed this way, the War Golem deals 1 additional Wound when wounding during this Combat.",
    squad: "WAR_GOLEM",
    count: 0,
    faction: "chapter",
  },

  {
    id: 165,
    key: 165,
    img: "images/factions/chapter/cards/static_field.jpg",
    cardCount: 4,
    upperAction: "TAZE",
    upperCost: "0",
    upperActionText:
      "Remove a DISCHARGE token from 1 (base_to_base) enemy model to perform a Backstab with the War Golem against that model.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "STATIC FIELD",
    lowerActionText:
      "Use right before a DEF roll. Remove a DISCHARGE token from 1 (base_to_base) enemy. The War Golem automatically wins this DEF roll. Restore 1 Endurance token to the War Golem.",
    squad: "WAR_GOLEM",
    count: 0,
    faction: "chapter",
  },
  {
    id: 166,
    key: 166,
    img: "images/factions/chapter/cards/steam_overload.jpg",
    cardCount: 4,
    upperAction: "CHARGING",
    upperCost: "1",
    upperActionText: "Charge all your Enhancements by 1 (charge)",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "STEAM OVERLOAD",
    lowerActionText:
      "Check all models withing Range 1 for Hit. Effect: 1 Wound (all models) and 1 DISCHARGE token (enemy models).",
    squad: "WAR_GOLEM",
    count: 0,
    faction: "chapter",
  },

  {
    id: 167,
    key: 167,
    img: "images/factions/demon/cards/control.jpg",
    cardCount: 1,
    upperAction: "SEDUCTION",
    upperCost: "0",
    upperActionText:
      "Each time Ish is WOunded by an enemy model, you may perform a Move, then a Combat with that model.",
    lowerCost: "0",
    costType: "charge",
    lowerAction: "CONTROL",
    lowerActionText:
      "Put a CONTROL token on an enemy Squad card (Range unlimited). (1 use per turn)",
    squad: "ISH",
    count: 0,
    faction: "demon",
    ignoreLimit: true,
  },

  {
    id: 168,
    key: 168,
    img: "images/factions/demon/cards/mind_blast.jpg",
    cardCount: 3,
    upperAction: "FRAIL",
    upperCost: "0",
    upperActionText:
      "During this Combat, a chosen enemy model has -2 ATT for each CONTROL token on its Squad card.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "MIND BLAST",
    lowerActionText:
      "Range 3. Check target for Hit. Add +1 to the roll for each CONTROL token on the target model Squad card.Effect: 1 Wound",
    squad: "ISH",
    count: 0,
    faction: "demon",
  },

  {
    id: 169,
    key: 169,
    img: "images/factions/demon/cards/possessed.jpg",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Demon Squad.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "POSSESSED",
    lowerActionText:
      "Choose an enemy model within Range 3 with at least 1 CONTROL token on its Squad card. Perform a Move with that model, then a Combat against every model (base_to_base) with it. The Possessed model gains +1 ATT and MOV for each CONTROL token on its Squad card.",
    squad: "ISH",
    count: 0,
    faction: "demon",
  },
  {
    id: 170,
    key: 170,
    img: "images/factions/demon/cards/punish_the_weak.jpg",
    cardCount: 4,
    upperAction: "TELEPORT",
    upperCost: "1",
    upperActionText: "Teleport 1 Demon model.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "PUNISH THE WEAK",
    lowerActionText:
      "Choose an enemy Squad. For each CONTROL token on its Squad card you may Backstab 1 model of this Squad (both with your models and enemy ones (base_to_base)).",
    squad: "ISH",
    count: 0,
    faction: "demon",
  },

  {
    id: 171,
    key: 171,
    img: "images/factions/dvergar/cards/borer.jpg",
    cardCount: 1,
    upperAction: "ENRAGE",
    upperCost: "0",
    upperActionText:
      "At the start of your Active turn, if Bullgrim has 2 or less Endurance tokens remaining, you may Change Mode with its squad card.",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "BORER",
    lowerActionText:
      "Put a QUARRY token on any free hex without a QUARRY token.",
    squad: "BULLGRIM",
    count: 0,
    faction: "dvergar",
    ignoreLimit: true,
  },
  {
    id: 172,
    key: 172,
    img: "images/factions/dvergar/cards/explosion.jpg",
    cardCount: 4,
    upperAction: "DEFENSE",
    upperCost: "0",
    upperActionText: "Gain +1 DEF during this Combat.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "EXPLOSION",
    lowerActionText:
      "Choose a Space with a QUARRY token. Check for Hit every model in that SPace (yours and enemy)., then remove this QUARRY token. Effect: 1 Wound .",
    squad: "BULLGRIM",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 173,
    key: 173,
    img: "images/factions/dvergar/cards/lethal_shot.jpg",
    cardCount: 4,
    upperAction: "STEP",
    upperCost: "0",
    upperActionText: "Move 1 Dvergar model.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "LETHAL SHOT",
    lowerActionText:
      "Choose a Space with a QUARRY token. During this turn, all models in this Space (yours and enemy) have a base DEF of 0.",
    squad: "BULLGRIM",
    count: 0,
    faction: "dvergar",
  },
  {
    id: 174,
    key: 174,
    img: "images/factions/dvergar/cards/stampede.jpg",
    cardCount: 3,
    upperAction: "TUNNELS",
    upperCost: "1",
    upperActionText: "Reposition 1 chosen QUARRY token by 1 Space..",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "STAMPEDE",
    lowerActionText:
      "Rage mode required. Line of Fire required. Choose an enemy model on a Space with a QUARRY token. Mvoe (base_to_base) with the chosen model in a straight line, then perform Combat against it. If Bullgrim deals a Wound, it deals 1 additional Wound for each Space it has moved through.",
    squad: "BULLGRIM",
    count: 0,
    faction: "dvergar",
  },

  {
    id: 175,
    key: 175,
    img: "images/factions/faceless/cards/pounce.jpg",
    cardCount: 4,
    upperAction: "REUSE",
    upperCost: "1",
    upperActionText: "Sacrifice 1 Faceless model to add 1 (crystal).",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "POUNCE",
    lowerActionText:
      "Choose a model with a SLASH token. Teleport Metalgorger to the same Space (Push allowed). Then, put SLASH token on each enemy model (base_to_base) with the Metalgorger.",
    squad: "METALGORGER",
    count: 0,
    faction: "faceless",
  },

  {
    id: 176,
    key: 176,
    img: "images/factions/faceless/cards/rip_and_tear.jpg",
    cardCount: 3,
    upperAction: "COMBAT",
    upperCost: "1",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "RIP AND TEAR",
    lowerActionText:
      "The Metalgorger Backstabs each (base_to_base) enemy model with a SLASH token.",
    squad: "METALGORGER",
    count: 0,
    faction: "faceless",
  },
  {
    id: 177,
    key: 177,
    img: "images/factions/faceless/cards/roar.jpg",
    cardCount: 4,
    upperAction: "MOVE",
    upperCost: "0",
    upperActionText: "with all models in 1 Faceless Squad.",
    lowerCost: "0",
    costType: "crystal",
    lowerAction: "ROAR",
    lowerActionText:
      "Range 2. Move all enemy models iwth at least 1 SLASH token up to 1 Space.",
    squad: "METALGORGER",
    count: 0,
    faction: "faceless",
  },

  {
    id: 178,
    key: 178,
    img: "images/factions/faceless/cards/slashing.jpg",
    cardCount: 1,
    upperAction: "CLAWS",
    upperCost: "0",
    upperActionText:
      "Put a SLASH token on (base_to_base) enemy model (once per turn).",
    lowerCost: "0",
    costType: "charge",
    lowerAction: "SLASHING",
    lowerActionText:
      "When Metalgorger deals a Wound, remove a SLASH token from wounded model to deal 1 additional WOund. Metalgorger cannot be Sacrificed.",
    squad: "METALGORGER",
    count: 0,
    faction: "faceless",
    ignoreLimit: true,
  },

  {
    id: 179,
    key: 179,
    img: "images/factions/reborn/cards/drake.jpg",
    cardCount: 1,
    upperAction: "NATURE'S TRAPS",
    upperCost: "1",
    upperActionText:
      "Put a TOXIC token on any chosen free hex withing Range 1 of Uulsot.",
    lowerCost: "1",
    costType: "charge",
    lowerAction: "DRAKE",
    lowerActionText:
      "Remove Uulsot from teh board and place on its Squad card. When you activate its Squad, place it in any Space on the board(it can Push).",
    squad: "UULSOT_THE_KEEPER",
    count: 0,
    faction: "reborn",
    ignoreLimit: true,
  },

  {
    id: 180,
    key: 180,
    img: "images/factions/reborn/cards/lunge.jpg",
    cardCount: 4,
    upperAction: "SPROUT",
    upperCost: "1",
    upperActionText:
      "Place 1 new Thicket (base_to_base) with one other Thicket or Thornheart Wood.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "LUNGE",
    lowerActionText:
      "Play when Uulsot is on its Squad card to place it in any empty Space on tehe board. Check for Hit all enemy models on each Space with a TOXIC token. Effect: 1 Wound.",
    squad: "UULSOT_THE_KEEPER",
    count: 0,
    faction: "reborn",
  },
  {
    id: 181,
    key: 181,
    img: "images/factions/reborn/cards/noxious_breath.jpg",
    cardCount: 4,
    upperAction: "FOG",
    upperCost: "1",
    upperActionText:
      "During this Combat, Uulsot gain +2 DEF for each Space with a TOXIC token within Range 1.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "NOXIOUS REATH",
    lowerActionText:
      "Perform a COmbat with a target enemy model. Remove any number of TOXIC tokens within Range 1 of the target. Gain +2 ATT for each removed TOXIC token during this Combat.",
    squad: "UULSOT_THE_KEEPER",
    count: 0,
    faction: "reborn",
  },

  {
    id: 182,
    key: 182,
    img: "images/factions/reborn/cards/toxic_vapor.jpg",
    cardCount: 3,
    upperAction: "HERBS",
    upperCost: "1",
    upperActionText:
      "Restore 1 Endurance token to any Reborn model (base_to_base) with any Thicket.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "TOXIC VAPOR",
    lowerActionText: "Reposition any number of TOXIC tokens by 1 Space.",
    squad: "UULSOT_THE_KEEPER",
    count: 0,
    faction: "reborn",
  },

  {
    id: 183,
    key: 183,
    img: "images/factions/rhazack/cards/defensive_matrix.jpg",
    cardCount: 4,
    upperAction: "ENERGY CLOUD",
    upperCost: "1",
    upperActionText: "Reposition the Rha-Zack Shrine to any empty Space.",
    lowerCost: "1",
    costType: "crystal",
    lowerAction: "DEFENSIVE MATRIX",
    lowerActionText:
      "Use after a DEF roll of the Destiny Titan. Add the number of unused (crystal) in your Crystal Pool to the roll.",
    squad: "DESTINY_TITAN",
    count: 0,
    faction: "rhazack",
  },
  {
    id: 184,
    key: 184,
    img: "images/factions/rhazack/cards/reactor.jpg",
    cardCount: 1,
    upperAction: "BEACON",
    upperCost: "1",
    upperActionText:
      "Place a BEACON token in any Space on the board. During your next Passive Turn, teleport the Destiny Titan to the Space with the BEACON token (it can Push). Remove the BEACON token.",
    lowerCost: "0",
    costType: "charge",
    lowerAction: "REACTOR",
    lowerActionText:
      "At the end of your Active turn, if you have at least 2 unused (crystal) in your Crystal Pool, Charge 1 chosen Enhancement by 1 (charge)",
    squad: "DESTINY_TITAN",
    count: 0,
    faction: "rhazack",
    ignoreLimit: true,
  },

  {
    id: 185,
    key: 185,
    img: "images/factions/rhazack/cards/tornado_strike.jpg",
    cardCount: 4,
    upperAction: "RECALL",
    upperCost: "1",
    upperActionText:
      "Reposition the Rha-Zack Shrine to an empty Space (base_to_base) with either the Destiny Titan or the BEACON token. Reresh 1 (crystal).",
    lowerCost: "2",
    costType: "crystal",
    lowerAction: "TORNADO STRIKE",
    lowerActionText:
      "Check for Hit all modes within Range 2 and in Line of Fire of the Destiny Titan. Add +1 to teh roll for each unused (crystal) in your Cyrstal Pool. Effect: 1 Wound",
    squad: "DESTINY_TITAN",
    count: 0,
    faction: "rhazack",
  },

  {
    id: 186,
    key: 186,
    img: "images/factions/rhazack/cards/unstable_force.jpg",
    cardCount: 3,
    upperAction: "COMMON SELF",
    upperCost: "0",
    upperActionText:
      "1 Rha-Zack model may use INI, DEF or ATT of any other Rha-Zack Squad for 1 Combat or Attribute check.",
    lowerCost: "3",
    costType: "crystal",
    lowerAction: "UNSTABLE FORCE",
    lowerActionText:
      "Destroy 1 unused (crystal) in your Crystal Pool. Perform a Backstab. Add +1 ATT for each 2 unused (crystal) in your Crystal Pool. You deal additional Wound.",
    squad: "DESTINY_TITAN",
    count: 0,
    faction: "rhazack",
  },
];
