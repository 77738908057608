export const squadTypes = [
  {
    id: 1,
    type: "ANY_SQUAD",
    name: "Any Squad",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "chapter",
  },
  {
    id: 2,
    type: "PURGE_DOCTOR",
    name: "Purge Doctor",
    img: "images/factions/chapter/units/purge_doctor.png",
    imgBack: "images/factions/chapter/units/purge_doctor_Defense.png",
    cardCount: 1,
    isElite: true,
    isCavalry: false,
    count: 0,
    faction: "chapter",
  },
  {
    id: 3,
    type: "PILGRIM",
    name: "Pilgrim",
    img: "images/factions/chapter/units/pilgrim.png",
    imgBack: "images/factions/chapter/units/pilgrim_run.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "chapter",
  },
  {
    id: 4,
    type: "HOLY_KNIGHT",
    name: "Holy Knight",
    img: "images/factions/chapter/units/holy_knight_1.png",
    imgBack: "images/factions/chapter/units/holy_knight_1_upgraded.png",
    cardCount: 3,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "chapter",
  },
  {
    id: 5,
    type: "RANGER",
    name: "Ranger",
    img: "images/factions/chapter/units/ranger.png",
    imgBack: "images/factions/chapter/units/ranger_aim.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "chapter",
  },
  {
    id: 6,
    type: "ANGEL_OF_DEATH",
    name: "Angel of Death",
    img: "images/factions/chapter/units/angel_of_death.png",
    imgBack: "images/factions/chapter/units/angel_of_death_flight.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "chapter",
  },
  {
    id: 7,
    type: "INFINITY_ANGEL",
    name: "Infinity Angel",
    img: "images/factions/rhazack/units/infinity_angel.png",
    imgBack: "images/factions/rhazack/units/infinity_angel_flight.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "rhazack",
  },
  {
    id: 8,
    type: "SOURCE_BLADE",
    name: "Source Blade",
    img: "images/factions/rhazack/units/source_blade.png",
    imgBack: "images/factions/rhazack/units/source_blade_running.png",
    cardCount: 1,
    isElite: true,
    isCavalry: false,
    count: 0,
    faction: "rhazack",
  },
  {
    id: 9,
    type: "STORM_BRINGER",
    name: "Storm Bringer",
    img: "images/factions/rhazack/units/storm_bringer.png",
    imgBack: "images/factions/rhazack/units/storm_bringer_energy.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "rhazack",
  },
  {
    id: 10,
    type: "STREAM_GUARD",
    name: "Stream Guard",
    img: "images/factions/rhazack/units/stream_guard_1.png",
    imgBack: "images/factions/rhazack/units/stream_guard_1_guard.png",
    cardCount: 2,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "rhazack",
  },
  {
    id: 11,
    type: "STREAM_SURFER",
    name: "Stream Surfer",
    img: "images/factions/rhazack/units/stream_surfer.png",
    imgBack: "images/factions/rhazack/units/stream_surfer_surfing.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "rhazack",
  },
  {
    id: 12,
    type: "SON_OF_KHYBER",
    name: "Son of Khyber",
    img: "images/factions/demon/units/son_of_khyber.png",
    imgBack: "images/factions/demon/units/son_of_khyber_rage.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "demon",
  },
  {
    id: 13,
    type: "MASTER_OF_ELEMENTS",
    name: "Master of Elements",
    img: "images/factions/demon/units/master_of_elements.png",
    imgBack: "images/factions/demon/units/master_of_elements_focus.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "demon",
  },
  {
    id: 14,
    type: "GOREHOUND",
    name: "Gorehound",
    img: "images/factions/demon/units/gorehound_1.png",
    imgBack: "images/factions/demon/units/gorehound_1_astral.png",
    cardCount: 2,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "demon",
  },
  {
    id: 15,
    type: "DARK_SCHOLAR",
    name: "Dark Scholar",
    img: "images/factions/demon/units/dark_scholar_1.png",
    imgBack: "images/factions/demon/units/dark_scholar_1_ritual.png",
    cardCount: 2,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "demon",
  },
  {
    id: 16,
    type: "BLOODSMITH",
    name: "Bloodsmith",
    img: "images/factions/demon/units/bloodsmith.png",
    imgBack: "images/factions/demon/units/bloodsmith_strong.png",
    cardCount: 1,
    isElite: true,
    isCavalry: false,
    count: 0,
    faction: "demon",
  },
  {
    id: 17,
    type: "AVENGER",
    name: "Avenger",
    img: "images/factions/dvergar/units/avenger.png",
    imgBack: "images/factions/dvergar/units/avenger_attack.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "dvergar",
  },
  {
    id: 18,
    type: "FLAME_KEEPER",
    name: "Flame Keeper",
    img: "images/factions/dvergar/units/flame_keeper.png",
    imgBack: "images/factions/dvergar/units/flame_keeper_active.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "dvergar",
  },
  {
    id: 19,
    type: "ENKINDLED",
    name: "Enkindled",
    img: "images/factions/dvergar/units/enkindled.png",
    imgBack: "images/factions/dvergar/units/enkindled_attack.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "dvergar",
  },
  {
    id: 20,
    type: "UNBROKEN",
    name: "Unbroken",
    img: "images/factions/dvergar/units/unbroken_1.png",
    imgBack: "images/factions/dvergar/units/unbroken_1_vigilance.png",
    cardCount: 2,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "dvergar",
  },
  {
    id: 21,
    type: "GYROBOT",
    name: "Gyrobot",
    img: "images/factions/dvergar/units/gyrobot.png",
    imgBack: "images/factions/dvergar/units/gyrobot_shooting.png",
    cardCount: 1,
    isElite: true,
    isCavalry: false,
    count: 0,
    faction: "dvergar",
  },
  {
    id: 22,
    type: "SHATTERER",
    name: "Shatterer",
    img: "images/factions/faceless/units/shatterer.png",
    imgBack: "images/factions/faceless/units/shatterer_joined.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "faceless",
  },
  {
    id: 23,
    type: "HARVESTER",
    name: "Harvester",
    img: "images/factions/faceless/units/harvester.png",
    imgBack: "images/factions/faceless/units/harvester_joined.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "faceless",
  },
  {
    id: 24,
    type: "BLADEBREEDER",
    name: "Bladebreeder",
    img: "images/factions/faceless/units/bladebreeder.png",
    imgBack: "images/factions/faceless/units/bladebreeder_joined.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "faceless",
  },
  {
    id: 25,
    type: "BERSERKER",
    name: "Berserker",
    img: "images/factions/faceless/units/berserker_1.png",
    imgBack: "images/factions/faceless/units/berserker_1_joined.png",
    imgBackEvolved: "images/factions/faceless/units/berserker_1_evolved.png",
    cardCount: 2,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "faceless",
  },
  {
    id: 26,
    type: "FLESHRIPPER",
    name: "Fleshripper",
    img: "images/factions/faceless/units/fleshripper.png",
    imgBack: "images/factions/faceless/units/fleshripper_1_joined.png",
    imgBackEvolved: "images/factions/faceless/units/fleshripper_1_evolved.png",
    cardCount: 1,
    isElite: true,
    isCavalry: false,
    count: 0,
    faction: "faceless",
  },
  {
    id: 27,
    type: "ORACLE_OF_WRATH",
    name: "Oracle of Wrath",
    img: "images/factions/reborn/units/oracle_of_wrath.png",
    imgBack: "images/factions/reborn/units/oracle_of_wrath_move.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "reborn",
  },
  {
    id: 28,
    type: "BLIGHT_STEED",
    name: "Blight Steed",
    img: "images/factions/reborn/units/blight_steed.png",
    imgBack: "images/factions/reborn/units/blight_steed_charger.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "reborn",
  },
  {
    id: 29,
    type: "WARBEAST",
    name: "Warbeast",
    img: "images/factions/reborn/units/warbeast.png",
    imgBack: "images/factions/reborn/units/warbeast_frenzy.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "reborn",
  },
  {
    id: 30,
    type: "WYRM",
    name: "Wyrm",
    img: "images/factions/reborn/units/wyrm.png",
    imgBack: "images/factions/reborn/units/wyrm_burrowed.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "reborn",
  },
  {
    id: 31,
    type: "TREEMAID",
    name: "Treemaid",
    img: "images/factions/reborn/units/treemaid.png",
    imgBack: "images/factions/reborn/units/treemaid_running.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "reborn",
  },
  {
    id: 32,
    type: "ANCIENT_GUARD",
    name: "Ancient Guard",
    img: "images/factions/reborn/units/ancient_guard.png",
    imgBack: "images/factions/reborn/units/ancient_guard_hard.png",
    cardCount: 1,
    isElite: true,
    isCavalry: false,
    count: 0,
    faction: "reborn",
  },
  {
    id: 33,
    type: "ANATHEMA",
    name: "Anathema",
    img: "images/factions/faceless/units/anathema.png",
    imgBack: "images/factions/faceless/units/anathema_abomination.png",
    cardCount: 1,
    isElite: false,
    isCavalry: true,
    count: 0,
    faction: "faceless",
  },
  {
    id: 34,
    type: "HOLY_RIDERS",
    name: "Holy Riders",
    img: "images/factions/chapter/units/holy_riders.png",
    imgBack: "images/factions/chapter/units/holy_riders_charge.png",
    cardCount: 1,
    isElite: false,
    isCavalry: true,
    count: 0,
    faction: "chapter",
  },
  {
    id: 35,
    type: "SUCCUBUS",
    name: "Succubus",
    img: "images/factions/demon/units/succubus.png",
    imgBack: "images/factions/demon/units/succubus_iliosuions.png",
    cardCount: 1,
    isElite: false,
    isCavalry: true,
    count: 0,
    faction: "demon",
  },
  {
    id: 36,
    type: "DRILLERS",
    name: "Drillers",
    img: "images/factions/dvergar/units/drillers.png",
    imgBack: "images/factions/dvergar/units/drillers_drill.png",
    cardCount: 1,
    isElite: false,
    isCavalry: true,
    count: 0,
    faction: "dvergar",
  },
  {
    id: 37,
    type: "FOREST_WRAITH",
    name: "Forest Wraith",
    img: "images/factions/reborn/units/forest_wraith.png",
    imgBack: "images/factions/reborn/units/forest_wraith_wraith.png",
    cardCount: 1,
    isElite: false,
    isCavalry: true,
    count: 0,
    faction: "reborn",
  },
  {
    id: 38,
    type: "JUMPSUIT",
    name: "Jumpsuit",
    img: "images/factions/rhazack/units/jumpsuit.png",
    imgBack: "images/factions/rhazack/units/jumpsuit_jump.png",
    cardCount: 1,
    isElite: false,
    isCavalry: true,
    count: 0,
    faction: "rhazack",
  },
  {
    id: 39,
    type: "PRIME_SHADOW",
    name: "Prime Shadow",
    img: "images/factions/darkness/units/prime_shadow.png",
    imgBack: "images/factions/darkness/units/prime_shadow_dark.png",
    cardCount: 1,
    isElite: false,
    isCavalry: true,
    count: 0,
    faction: "darkness",
  },
  {
    id: 40,
    type: "ABYSSAL_EMPRESS",
    name: "Abyssal Empress",
    img: "images/factions/darkness/units/abyssal_empress.png",
    imgBack: "images/factions/darkness/units/abyssal_empress_dark.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "darkness",
  },
  {
    id: 41,
    type: "BELL_CROW",
    name: "Bell Crow",
    img: "images/factions/darkness/units/bell_crow.png",
    imgBack: "images/factions/darkness/units/bell_crow_dark.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "darkness",
  },
  {
    id: 42,
    type: "IMPOSTOR",
    name: "Impostor",
    img: "images/factions/darkness/units/impostor.png",
    imgBack: "images/factions/darkness/units/impostor_dark.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "darkness",
  },
  {
    id: 43,
    type: "SHADOW",
    name: "Shadow",
    img: "images/factions/darkness/units/shadow.png",
    imgBack: "images/factions/darkness/units/shadow_dark.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "darkness",
  },
  {
    id: 44,
    type: "SLAUGHTER_CROWS",
    name: "Slaughter Crows",
    img: "images/factions/darkness/units/slaughter_crows.png",
    imgBack: "images/factions/darkness/units/slaughter_crows_dark.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "darkness",
  },
  {
    id: 45,
    type: "VOID_SPECTER",
    name: "Void Specter",
    img: "images/factions/darkness/units/void_specter.png",
    imgBack: "images/factions/darkness/units/void_specter_dark.png",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    count: 0,
    faction: "darkness",
  },
  {
    id: 46,
    type: "WAR_GOLEM",
    name: "War Golem",
    img: "images/factions/chapter/units/war_golem.jpg",
    imgBack: "images/factions/chapter/units/war_golem_back.jpg",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    isBoss: true,
    count: 0,
    faction: "chapter",
  },
  {
    id: 47,
    type: "ISH",
    name: "Ish",
    img: "images/factions/demon/units/ish.jpg",
    imgBack: "images/factions/demon/units/ish_back.jpg",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    isBoss: true,
    count: 0,
    faction: "demon",
  },
  {
    id: 48,
    type: "BULLGRIM",
    name: "Bullgrim",
    img: "images/factions/dvergar/units/bullgrim.jpg",
    imgBack: "images/factions/dvergar/units/bullgrim_back.jpg",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    isBoss: true,
    count: 0,
    faction: "dvergar",
  },
  {
    id: 49,
    type: "METALGORGER",
    name: "Metalgorger",
    img: "images/factions/faceless/units/metalgorger.jpg",
    imgBack: "images/factions/faceless/units/metalgorger_back.jpg",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    isBoss: true,
    count: 0,
    faction: "faceless",
  },
  {
    id: 50,
    type: "UULSOT_THE_KEEPER",
    name: "Uulsot the keeper",
    img: "images/factions/reborn/units/uulsot_the_keeper.jpg",
    imgBack: "images/factions/reborn/units/uulsot_the_keeper_back.jpg",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    isBoss: true,
    count: 0,
    faction: "reborn",
  },
  {
    id: 51,
    type: "DESTINY_TITAN",
    name: "Destiny Titan",
    img: "images/factions/rhazack/units/destiny_titan.jpg",
    imgBack: "images/factions/rhazack/units/destiny_titan_back.jpg",
    cardCount: 1,
    isElite: false,
    isCavalry: false,
    isBoss: true,
    count: 0,
    faction: "rhazack",
  },
];
