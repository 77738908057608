export const defaultDecks = [
  {
    id: 0,

    faction: "chapter",
    squads: [6, 4, 4, 3, 5],
    cards: [
      1,
      3,
      3,
      3,
      4,
      5,
      6,
      7,
      8,
      8,
      9,
      10,
      11,
      12,
      12,
      15,
      15,
      16,
      16,
      16,
      16,
      17,
      18,
      19,
      20,
    ],
    name: "Preconstructed starting deck",
  },
];
